/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

export default {
    google_api_key: "AIzaSyCy5sfjibrc5Q_94vGvGK-TwkOxc73UzEM",
    posthog_api_key: "phc_nkyehJO1uSWVUue3U8IR0T1xuqnEdKrGIoyF0GJAG6M",
    ga_tracking_code: "UA-74964127-1",
    ga4_measurement_code: "G-G2Y04LMES7",
    cookie_name: "token",

    graphql_api_url: "https://platform.convergence.finance/api-service/public",
    graphql_api_ws_url: "wss://platform.convergence.finance/api-service/public/subscriptions",
    graphql_service_api_url: "TODO",

    contentful_environment: "master",
    contentful_space_id: "4cgqlwde6qy0",
    contentful_access_token: "cbb0915c9b1ef8c1b8a49620aa0a5237c110fd2229fbe91126e50d3569746a82",
    contentful_file_path: "api/file",
    contentful_proxy: "https://platform.convergence.finance/api-service/public/contentful-proxy"
};
