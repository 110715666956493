/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import {
    getDomiciledCountries,
    getAllCountriesArray,
    getAllImpactThemes,
    getAllRegions,
    getAllSectors,
    getImpactThemesAndObjectives,
    getInvestmentInstrumentsAndSubInstruments,
    getRegionsAndCountries,
    getSectorsAndSubSectors,
    getAllSubSectors
} from "./utils/CommonUtils";
import SearchInvestorFilter from "./components/general/SearchInvestorFilter";
import MinMaxDealSizeFilter from "./components/general/MinMaxDealSizeFilter";
import KeywordSearchFilter from "./components/general/KeywordSearchFilter";
import MinMaxLaunchYear from "./components/general/MinMaxLaunchYear";
import MinMaxCloseYear from "./components/general/MinMaxCloseYear";
import DesignFundingWindowFilter from "./components/general/DesignFundingWindowFilter";

export const BRAND_COLOURS = {
    primary: "#337ab7",
    secondary: "#26a4d9",
    blue: "#007bff",
    indigo: "#6610f2",
    purple: "#6f42c1",
    pink: "#e83e8c",
    red: "#dc3545",
    orange: "#fd7e14",
    yellow: "#ffc107",
    green: "#28a745",
    teal: "#20c997",
    cyan: "#17a2b8",
    white: "#ffffff",
    gray: "#575757",
    grayDark: "#333333",
    success: "#28a745",
    info: "#17a2b8",
    warning: "#ffc107",
    danger: "#dc3545",
    light: "#f8f9fa",
    dark: "#333333",
    black: "#000"
};

export const COLORS = [
    "#14588C",
    "#1D7BA3",
    "#7DC8E8",
    "#A8DBF0",
    "#CBCBCB",
    "#C1C1C1",
    "#A2A2A2",
    "#14588C",
    "#1D7BA3",
    "#7DC8E8",
    "#A8DBF0",
    "#CBCBCB",
    "#C1C1C1",
    "#A2A2A2",
    "#14588C",
    "#1D7BA3",
    "#7DC8E8",
    "#A8DBF0",
    "#CBCBCB",
    "#C1C1C1",
    "#A2A2A2"
];

// Pages
export const PAGE_PRIVACY_POLICY = "6jZbt7xL1Y0CyYwsikIWwQ";
export const PAGE_SEA_POLICY = "2K57q0blOEICgMHJDsL1iN";
export const PAGE_TERMS_OF_USE = "8n3j2USZheug6EKWwM2a6";
export const PAGE_DATA_METHODOLOGY = "6wC4TFRUkgIIKSuuM4osa6";
export const PAGE_ABOUT = "4LpjxwD4y4OYsK6ksqgSsU";
export const PAGE_FAQ = "aboutFaq";
export const PAGE_CAREERS = "aboutCareers";
export const PAGE_NEWS = "XDQK71OQLPP7DSLPRC55";
export const PAGE_EVENTS = "6t8hrMAUzSkKCsamCIaauq";
export const PAGE_DESIGN_FUNDING_WINDOW = "designFundingWindow";
export const PAGE_GRANT = "60QTjSLt4sImUGsiMWqcYI";
export const PAGE_INITIATIVES = "specialInitiative";
export const PAGE_KNOWLEDGE = "5fMmpZEMY84iq4GKUUgwwu";
export const PAGE_ABOUT_CONTENT_TYPE = "about";
export const PAGE_ABOUT_LEADERSHIP = "aboutLeadership";
export const PAGE_IN_THE_MEDIA = "aboutPress";

export const PERMISSION_VIEW_ALL = "VIEW_ALL";
export const PERMISSION_EDIT = "EDIT";
export const PERMISSION_VIEW = "VIEW";
export const PERMISSION_SUMMARY_ANALYSIS = "SUMMARY_ANALYSIS";
export const PERMISSION_CREATE = "CREATE";
export const PERMISSION_DELETE = "DELETE";
export const PERMISSION_SEARCH = "SEARCH";
export const PERMISSION_RECEIVE_MESSAGE = "RECEIVE_MESSAGE";
export const PERMISSION_DOWNLOAD = "DOWNLOAD";

export const NEWS_TYPES = [
    "All",
    "Announcement",
    "Blog",
    "Call for Applications",
    "Event",
    "News",
    "Press Release",
    "Publication",
    "Videos",
    "Data Review"
];

export const DEAL_SIZE_BY_RANGE_Y_AXIS_LABEL = [
    "<5M",
    "5-10M",
    "10-25M",
    "25-50M",
    "50-100M",
    "100-250M",
    "250-500M",
    "500-1000M",
    ">1000M"
];

export const PERMISSIONS = {
    institution: [
        "CAN_SEARCH",
        "CAN_FILTER",
        "CAN_VIEW_LIST",
        "CAN_VIEW_OWN",
        "CAN_EDIT_OWN",
        "CAN_DELETE_OWN",
        "CAN_VIEW_ANY",
        "CAN_EDIT_ANY",
        "CAN_DELETE_ANY"
    ],
    deals: [
        "CAN_SEARCH",
        "CAN_FILTER",
        "CAN_CREATE",
        "CAN_VIEW_LIST",
        "CAN_VIEW_OWN",
        "CAN_EDIT_OWN",
        "CAN_DELETE_OWN",
        "CAN_VIEW_INSTITUTIONS",
        "CAN_EDIT_INSTITUTIONS",
        "CAN_DELETE_INSTITUTIONS",
        "CAN_CREATE_ANY",
        "CAN_VIEW_ANY",
        "CAN_EDIT_ANY",
        "CAN_DELETE_ANY"
    ]
};

export const NO = 0;
export const YES = 1;

export const IS_INACTIVE = 0;
export const IS_ACTIVE = 1;

export const USER_STATUS_PENDING_ACTIVATION = 0;
export const USER_STATUS_NOT_JOINED_INSTITUTION = 1;
export const USER_STATUS_NOT_COMPLETED_PREFERENCES = 2;
export const USER_STATUS_ACTIVE = 10;

export const USER_SUB_STATUS_STANDARD = 0;
export const USER_SUB_STATUS_TRIAL_ENDED = 1;

export const USER_TYPE_STANDARD = 0;
export const USER_TYPE_ADMIN = 1;

export const USER_PURPOSE_FOR_USING_CONVERGENCE = [
    {
        value: "Connect with active deals",
        label: "Connect with active deals"
    },
    {
        value: "Connect with investors",
        label: "Connect with investors"
    },
    {
        value: "Access data & intelligence",
        label: "Access data & intelligence"
    }
];

export const INSTITUTION_APPROVAL_METHOD_MANUAL = 0;
export const INSTITUTION_APPROVAL_METHOD_AUTOMATIC = 1;

export const INSTITUTION_AUTOMATICALLY_APPROVED = 0;
export const INSTITUTION_AWAITING_MANUAL_APPROVAL = 1;

export const INSTITUTION_STATUS_DRAFT = 0;
export const INSTITUTION_STATUS_PENDING_APPROVAL = 1;
export const INSTITUTION_STATUS_COMPLIANCE_REVIEW = 2;
export const INSTITUTION_STATUS_APPROVED = 10;
export const INSTITUTION_STATUS_DENIED_APPROVAL = 20;
export const INSTITUTION_STATUS_CANCELLED = 21;
export const INSTITUTION_STATUS_SUSPENDED = 22;
export const INSTITUTION_STATUS_REACTIVATED = 23;

export const INSTITUTION_STATUS_LABELS = {
    [INSTITUTION_STATUS_DRAFT]: "Draft",
    [INSTITUTION_STATUS_PENDING_APPROVAL]: "Pending Approval",
    [INSTITUTION_STATUS_COMPLIANCE_REVIEW]: "Compliance Review",
    [INSTITUTION_STATUS_APPROVED]: "Approved",
    [INSTITUTION_STATUS_DENIED_APPROVAL]: "Denied",
    [INSTITUTION_STATUS_REACTIVATED]: "Reactivated"
};

export const INSTITUTION_STATUS_BADGES = {
    [INSTITUTION_STATUS_DRAFT]: "warning",
    [INSTITUTION_STATUS_PENDING_APPROVAL]: "info",
    [INSTITUTION_STATUS_COMPLIANCE_REVIEW]: "info",
    [INSTITUTION_STATUS_APPROVED]: "success",
    [INSTITUTION_STATUS_DENIED_APPROVAL]: "danger",
    [INSTITUTION_STATUS_REACTIVATED]: "info"
};

export const INSTITUTION_SUB_STATUS_STANDARD = 10;
export const INSTITUTION_SUB_STATUS_LEGACY = 20;

export const DOCUMENT_STATUS_UPLOADING = 0;
export const DOCUMENT_STATUS_FAILED = 1;
export const DOCUMENT_STATUS_SUCCESS = 10;

export const DOCUMENT_STATUS_LABELS = {
    [DOCUMENT_STATUS_UPLOADING]: "UPLOADING",
    [DOCUMENT_STATUS_FAILED]: "FAILED",
    [DOCUMENT_STATUS_SUCCESS]: "SUCCESS"
};

export const DEAL_STATUS_DRAFT = 0;
export const DEAL_STATUS_PENDING_APPROVAL = 1;
export const DEAL_STATUS_DECLINED = 2;
export const DEAL_STATUS_HISTORICAL = 3;
export const DEAL_STATUS_ACTIVE = 10;
export const DEAL_STATUS_CLOSED = 11;
export const DEAL_STATUS_CANCELLED = 12;
export const DEAL_STATUS_EXPIRED = 13;

export const DESIGN_FUNDING_STATUS_PENDING_ACTIVATION = 0;
export const DESIGN_FUNDING_STATUS_PENDING_REVIEW = 1;
export const DESIGN_FUNDING_STATUS_IN_REVIEW = 2;
export const DESIGN_FUNDING_STATUS_ADDITIONAL_INFORMATION_REQUIRED = 3;
export const DESIGN_FUNDING_STATUS_ADDITIONAL_INFORMATION_REVIEW = 4;
export const DESIGN_FUNDING_STATUS_SELECTED_FOR_FUNDING = 5;
export const DESIGN_FUNDING_STATUS_NOT_SELECTED_FOR_FUNDING = 6;
export const DESIGN_FUNDING_STATUS_APPLICATION_WITHDRAWN = 7;

export const DESIGN_FUNDING_STATUS_LABELS = {
    [DESIGN_FUNDING_STATUS_PENDING_ACTIVATION]: "Pending Activation",
    [DESIGN_FUNDING_STATUS_PENDING_REVIEW]: "Proposal review yet to start",
    [DESIGN_FUNDING_STATUS_IN_REVIEW]: "Proposal review in progress",
    [DESIGN_FUNDING_STATUS_ADDITIONAL_INFORMATION_REQUIRED]:
        "Additional information required",
    [DESIGN_FUNDING_STATUS_ADDITIONAL_INFORMATION_REVIEW]:
        "Additional information review in process",
    [DESIGN_FUNDING_STATUS_SELECTED_FOR_FUNDING]: "Selected for funding award",
    [DESIGN_FUNDING_STATUS_NOT_SELECTED_FOR_FUNDING]:
        "Not selected for funding award",
    [DESIGN_FUNDING_STATUS_APPLICATION_WITHDRAWN]: "Application withdrawn"
};

export const ELIGIBILITY_GROUP_0 = "ELIGIBILITY_GROUP_0";
export const ELIGIBILITY_GROUP_1 = "ELIGIBILITY_GROUP_1";
export const ELIGIBILITY_GROUP_2 = "ELIGIBILITY_GROUP_2";
export const ELIGIBILITY_GROUP_3 = "ELIGIBILITY_GROUP_3";
export const ELIGIBILITY_GROUP_4 = "ELIGIBILITY_GROUP_4";
export const ELIGIBILITY_GROUP_5 = "ELIGIBILITY_GROUP_5";
export const ELIGIBILITY_GROUP_6 = "ELIGIBILITY_GROUP_6";
export const ELIGIBILITY_GROUP_7 = "ELIGIBILITY_GROUP_7";
export const ELIGIBILITY_GROUP_8 = "ELIGIBILITY_GROUP_8";
export const ELIGIBILITY_GROUP_9 = "ELIGIBILITY_GROUP_9";
export const ELIGIBILITY_GROUP_10 = "ELIGIBILITY_GROUP_10";

export const TERMS_AND_CONDITIONS = {
    membership_acknowledgement: "Membership Acknowledgement",
    general_registration_agreement: "General Registration Agreement",
    investor_registration_agreement: "Investor Registration Agreement",
    non_investment_registration_agreement:
        "Non-Investment Registration Agreement",
    non_investment_eligibility_checklist:
        "Non-Investment Eligibility to Access the Investment Network (Insurers and Grant Funders)",
    country_investor_eligibility_checklist: "Investor Eligibility Checklist",
    dfi_registration_agreement:
        "Development Finance Institution Registration Agreement"
};

export const ELIGIBLE_PACKAGES = [
    {
        id: "26f8b5df-c2c1-452b-819f-c27ea9899d43",
        name: "Public Sector - Development Agencies",
        details: [
            {
                name: "Learning and Capacity",
                description:
                    "Convergence supports development agencies to: i) understand the potential of blended finance to support in achieving the SDGs, ii) build the case internally for blended finance based on the impact of blended finance to-date, iii) develop a blended finance strategy and approach, and iv) develop the capacity to participate effectively in blended finance transactions, from due diligence to monitoring.",
                features: [
                    "In-depth, on-boarding meeting to ascertain development agency's interests and needs for blended finance",
                    "Quarterly conference call with development agency to provide update on blended finance developments and discuss organization's current blended finance interests/needs",
                    "Participation in an annual development agency knowledge exchange hosted with OECD",
                    "Access to online educational modules on blended finance",
                    "Early access or preferential rates for blended finance Executive Education programs at partner Universities",
                    "Additional customized services on request"
                ]
            },
            {
                name: "Data and Intelligence",
                description:
                    "Convergence offers development agencies the data and intelligence they need to make informed blended finance decisions. Convergence maintains the largest and most detailed database of past blended finance transactions and draws analysis and insights from this database on a continuous basis. Convergence case studies profile blended finance transactions, distilling best practices and lessons.",
                features: [
                    "Access to data on past blended finance transactions, including impact targets, impact approach, blending approach, and investors in the transaction",
                    "Review of development agency's past blended finance activity to ensure it is captured in Convergence database",
                    "Blended finance investment trend data, for Convergence members that deploy capital",
                    "Quarterly briefings providing critical analysis and insights into blended finance deal trends",
                    "Analytical tools to evaluate deal trends (e.g., for specific SDGs, regions, blending approach)",
                    "Customized analysis of database at member request, provided quarterly",
                    "Quarterly Convergence-authored case studies on blended finance transactions",
                    "Curated knowledge library with Convergence publications as well as key industry publications",
                    "Knowledge and data recommendations based on member interests and preferences",
                    "Quarterly webinars on select topics",
                    "Monthly newsletter with latest membership news and activity",
                    "Additional customized services on request"
                ]
            },
            {
                name: "Deal Flow and Networking",
                description:
                    "Convergence provides development agencies with access to high quality deal flow to increase their impact, as well as broad networking opportunities to connect with key stakeholders in the market. Convergence has a large membership group with strong representation across public investors, philanthropic foundations, private investors, and the leading deal sponsors in the space.",
                features: [
                    "View profiles of Convergence members, their interests and focus areas",
                    "Access information on, and connect with, high quality blended finance opportunities currently fundraising",
                    "Support in placing the development agency's own incubators and similar programs on the Convergence site, provided there is a fit with our blended finance mission",
                    "Secure messaging with investors and deal sponsors",
                    "Quarterly Convergence member meetings and events",
                    "Event tracker of events relevant to blended finance",
                    "Additional customized services on request"
                ]
            }
        ],
        requirements: {
            roles: ["ROLE_INVESTOR"],
            institution_type: (institutionTypes) => {
                if (!institutionTypes) {
                    return false;
                }

                return (
                    institutionTypes.includes("Government Entity") === true ||
                    institutionTypes.includes("Multilateral Entity") === true
                );
            },
            institution_sub_type: (institutionSubTypes) => {
                if (!institutionSubTypes) {
                    return false;
                }

                return (
                    institutionSubTypes.includes("Government Aid Agency") ===
                        true ||
                    institutionSubTypes.includes(
                        "Government Department / Ministry"
                    ) === true ||
                    institutionSubTypes.includes(
                        "Government Fund / Programme"
                    ) === true ||
                    institutionSubTypes.includes(
                        "Public Agency / Enterprise"
                    ) === true ||
                    institutionSubTypes.includes(
                        "Subnational Government Entity"
                    ) === true ||
                    institutionSubTypes.includes(
                        "Multilateral Fund / Programme"
                    ) === true ||
                    institutionSubTypes.includes(
                        "Multilateral Organization"
                    ) === true
                );
            }
        }
    },
    {
        id: "4f4d3019-140b-4b1d-a922-97c5ea2d5cc9",
        name: "Philanthropic Foundations",
        details: [
            {
                name: "Deal Flow and Networking",
                description:
                    "Convergence provides foundations with access to high quality deal flow to increase their impact, as well as broad networking opportunities to connect with key stakeholders in the market. Convergence has a large membership group with strong representation across public investors, philanthropic foundations, private investors, and the leading deal sponsors in the space.",
                features: [
                    "View profiles of Convergence members, their interests and focus areas",
                    "Access information on, and connect with, high quality and high impact blended finance opportunities currently fundraising",
                    "Support in placing the foundation's own incubators and similar programs on the Convergence site, provided there is a fit with our blended finance mission",
                    "Secure messaging with like-minded investors and deal sponsors",
                    "Quarterly Convergence member meetings and events",
                    "Event tracker of events relevant to blended finance",
                    "Additional customized services on request"
                ]
            },
            {
                name: "Data and Intelligence",
                description:
                    "Convergence offers foundations the data and intelligence they need to make informed and impactful blended finance decisions. Convergence maintains the largest and most detailed database of past blended finance transactions and draws analysis and insights from this database on a continuous basis. Convergence case studies profile blended finance transactions, distilling best practices, lessons, and impact.",
                features: [
                    "Access to data on past blended finance transactions, including impact targets, impact approach, blending approach, and investors in the transaction",
                    "Review of foundation's past blended finance activity to ensure it is captured in Convergence database",
                    "Blended finance investment trend data, for Convergence members that deploy capital",
                    "Quarterly briefings providing critical analysis and insights into blended finance deal trends",
                    "Analytical tools to evaluate deal trends (e.g., for specific SDGs, regions, blending approach)",
                    "Customized analysis of database at member request, provided quarterly",
                    "Quarterly Convergence-authored case studies on blended finance transactions",
                    "Curated knowledge library with Convergence publications as well as key industry publications",
                    "Knowledge and data recommendations based on foundation interests and preferences",
                    "Quarterly webinars on select topics",
                    "Monthly newsletter with latest membership news and activity",
                    "Additional customized services on request"
                ]
            },
            {
                name: "Learning and Capacity",
                description:
                    "Convergence supports foundations to: i) understand the potential of blended finance to drive impact, ii) build the case internally for blended finance based on the impact of blended finance to-date, iii) develop a blended finance strategy and approach, and iv) develop the capacity to participate effectively in blended finance transactions, from due diligence to monitoring.",
                features: [
                    "In-depth, on-boarding meeting to ascertain foundation's interests and needs for blended finance",
                    "Quarterly conference call with development foundation to provide update on blended finance developments and discuss organization's current blended finance interests/needs",
                    "Access to online educational modules on blended finance",
                    "Early access or preferential rates for blended finance Executive Education programs at partner Universities",
                    "Additional customized services on request"
                ]
            }
        ],
        requirements: {
            roles: ["ROLE_INVESTOR"],
            institution_type: (institutionTypes) => {
                if (!institutionTypes) {
                    return false;
                }

                return (
                    institutionTypes.includes("Charitable Organization") ===
                        true ||
                    institutionTypes.includes("Endowment / Foundation") ===
                        true ||
                    institutionTypes.includes("Research Institute") === true
                );
            },
            institution_sub_type: (institutionSubTypes) => {
                if (!institutionSubTypes) {
                    return false;
                }

                return (
                    institutionSubTypes.includes(
                        "Faith-based NGO / Non-profit"
                    ) === true ||
                    institutionSubTypes.includes(
                        "International NGO / Non-profit"
                    ) === true ||
                    institutionSubTypes.includes(
                        "National NGO / Non-profit"
                    ) === true ||
                    institutionSubTypes.includes("Corporate Foundation") ===
                        true ||
                    institutionSubTypes.includes("Endowment Fund") === true ||
                    institutionSubTypes.includes("Private Foundation") ===
                        true ||
                    institutionSubTypes.includes("Think Tank") === true ||
                    institutionSubTypes.includes("University") === true
                );
            }
        }
    },
    {
        id: "4f7f2b2d-e1e4-434d-b8e9-f64ace1c81f9",
        name: "Public Sector - Commercial-Development Mandate",
        details: [
            {
                name: "Learning and Capacity",
                description:
                    "For MDBs and DFIs entering the area of blended finance, Convergence supports them to: i) understand the potential of blended finance to support in achieving the SDGs, ii) build the case internally for blended finance based on the impact of blended finance to-date, iii) develop a blended finance strategy and approach, and iv) develop the capacity to participate effectively in blended finance transactions, from due diligence to monitoring. For institutions well-versed in application of blended finance, Convergence supports them to: i) exchange knowledge with other institutions active in the blended finance arena, and ii) distill their blended finance learnings and impact to the market.",
                features: [
                    "In-depth, on-boarding meeting to ascertain member's interests and needs for blended finance",
                    "Quarterly conference call with members to provide update on blended finance developments and discuss organization's current blended finance interests/needs",
                    "Participation in Convergence knowledge exchanges, to disseminate lessons from the institution's own blended finance activities",
                    "Access to online educational modules on blended finance",
                    "Early access or preferential rates for blended finance executive education programs at partner Universities",
                    "Additional customized services on request"
                ]
            },
            {
                name: "Data and Intelligence",
                description:
                    "Convergence offers MDBs and DFIs the data and intelligence they need to make informed blended finance decisions and benchmark themselves against other institutions. Convergence maintains the largest and most detailed database of past blended finance transactions and draws analysis and insights from this database on a continuous basis. Convergence case studies profile blended finance transactions, distilling best practices and lessons.",
                features: [
                    "Access to data on past blended finance transactions, including impact targets, impact approach, blending approach, and investors in the transaction",
                    "Review of development agency past blended finance activity to ensure it is captured in Convergence database",
                    "Blended finance investment trend data for Convergence members that deploy capital",
                    "Quarterly briefings providing critical analysis and insights into blended finance deal trends",
                    "Analytical tools to analyze deal trends (e.g., for specific SDGs, regions, blending approach)",
                    "Bespoke analysis of database at member request, provided quarterly (e.g. investment trend in particular sector / regions, innovative investment instruments etc.",
                    "Quarterly Convergence-authored case studies on blended finance transactions",
                    "Curated knowledge library with Convergence publications as well as key industry publications",
                    "Knowledge and data recommendations based on member interests and preferences",
                    "Quarterly informational webinars on select topics",
                    "Monthly newsletter with latest membership news and activity",
                    "Additional customized services on request"
                ]
            },
            {
                name: "Deal Flow and Networking",
                description:
                    "Convergence provides MDBs and DFIs with access to high quality deal flow to increase their impact, as well as broad networking opportunities to connect with key stakeholders in the market. Convergence has a large membership group with strong representation across public investors, philanthropic foundations, private investors, and the leading deal sponsors in the space.",
                features: [
                    "View profiles of Convergence members and their interests and focus areas",
                    "Access information on, and connect with, high quality blended finance opportunities currently fundraising",
                    "Fundraising deal recommendations based on member interests and preferences",
                    "Secure messaging between investors and deal sponsors",
                    "Quarterly Convergence member meetings and events",
                    "Event tracker of events relevant to blended finance",
                    "Additional customized services on request"
                ]
            }
        ],
        requirements: {
            roles: ["ROLE_INVESTOR"],
            institution_type: (institutionTypes) => {
                if (!institutionTypes) {
                    return false;
                }

                return (
                    institutionTypes.includes("Government Entity") === true ||
                    institutionTypes.includes("Multilateral Entity") === true
                );
            },
            institution_sub_type: (institutionSubTypes) => {
                if (!institutionSubTypes) {
                    return false;
                }

                return (
                    institutionSubTypes.includes(
                        "National Development Finance Institution"
                    ) === true ||
                    institutionSubTypes.includes(
                        "Multilateral Development Bank"
                    ) === true
                );
            }
        }
    },
    {
        id: "0e5cccec-75ae-4ee6-bb0a-71e1e9ce4046",
        name: "Private Sector Investors",
        details: [
            {
                name: "Deal Flow and Networking",
                description:
                    "Convergence provides private investors with access to diversified deal flow with anchor commitments, as well as broad networking opportunities to connect with key stakeholders in the market. Convergence has a large membership group with strong representation across public investors, philanthropic foundations, private investors, and the leading deal sponsors in the space.",
                features: [
                    "View profiles of other Convergence members, their interests and focus areas",
                    "Access information on, and connect with, high quality blended finance opportunities currently fundraising",
                    "Secure messaging with other investors and deal sponsors",
                    "Customized Convergence support to connect with deals/members on request",
                    "Quarterly Convergence member meetings and events",
                    "Opportunities to share key issues in structuring deals with others. Convergence offers preference to its members when inviting speakers for training programs for development agencies aimed at building their ability to engage in blended finance.",
                    "Additional customized services on request"
                ]
            },
            {
                name: "Data and Intelligence",
                description:
                    "Convergence offers private investors the data and intelligence they need to make informed blended finance decisions. Convergence maintains the largest and most detailed database of past blended finance transactions and draws analysis and insights from this database on a continuous basis. Convergence case studies profile blended finance transactions, distilling best practices and lessons.",
                features: [
                    "Access to data on past blended finance transactions, including impact targets, impact approach, blending approach, and investors in the transaction",
                    "Blended finance investment trend data, for Convergence members that deploy capital",
                    "Quarterly briefings providing critical analysis and insights into blended finance deal trends",
                    "Analytical tools to evaluate deal trends (e.g., for specific SDGs, regions, blending approach)",
                    "Quarterly Convergence-authored case studies on blended finance transactions",
                    "Curated knowledge library with Convergence publications as well as key industry publications",
                    "Convergence shapes its knowledge and data offerings based on member interests and preferences",
                    "Quarterly webinars on select topics",
                    "Monthly newsletter with latest membership news and activity",
                    "Additional customized services on request"
                ]
            },
            {
                name: "Learning and Capacity",
                description:
                    "Convergence supports private investors to: i) understand the potential of blended finance to support in create investable opportunities in developing countries, ii) build the case internally for blended finance based on the results of blended finance to-date, iii) develop a blended finance strategy and approach, and iv) develop the capacity to participate effectively in blended finance transactions, from due diligence to monitoring.",
                features: [
                    "In-depth, on-boarding meeting to ascertain member's interests and needs for blended finance",
                    "Quarterly conference call with members to provide update on blended finance developments and discuss organization's current blended finance interests/needs",
                    "Participation in Convergence knowledge exchanges, to disseminate lessons from the institution's own blended finance activities",
                    "Access to online educational modules on blended finance",
                    "Early access or preferential rates for blended finance executive education programs at partner Universities",
                    "Additional customized services on request"
                ]
            }
        ],
        requirements: {
            roles: ["ROLE_INVESTOR"],
            institution_type: (institutionTypes) => {
                if (!institutionTypes) {
                    return false;
                }

                return (
                    institutionTypes.includes("Association / Network") ===
                        true ||
                    institutionTypes.includes("Business") === true ||
                    institutionTypes.includes("Financial Institution") ===
                        true ||
                    institutionTypes.includes("Government Entity") === true ||
                    institutionTypes.includes(
                        "Investment Management Company"
                    ) === true ||
                    institutionTypes.includes("Service Provider") === true ||
                    institutionTypes.includes("Other") === true
                );
            },
            institution_sub_type: (institutionSubTypes) => {
                if (!institutionSubTypes) {
                    return false;
                }

                return (
                    institutionSubTypes.includes(
                        "Industry / Trade Association"
                    ) === true ||
                    institutionSubTypes.includes(
                        "Informal Association / Network"
                    ) === true ||
                    institutionSubTypes.includes("Member Network") === true ||
                    institutionSubTypes.includes(
                        "Large / International Corporation"
                    ) === true ||
                    institutionSubTypes.includes(
                        "Small / Medium-Sized Enterprise"
                    ) === true ||
                    institutionSubTypes.includes("Social Enterprise") ===
                        true ||
                    institutionSubTypes.includes("Other") === true ||
                    institutionSubTypes.includes("Commercial Bank") === true ||
                    institutionSubTypes.includes(
                        "Diversified Financial Institution"
                    ) === true ||
                    institutionSubTypes.includes(
                        "Finance Company (non-deposit taking)"
                    ) === true ||
                    institutionSubTypes.includes("Insurance Company") ===
                        true ||
                    institutionSubTypes.includes("Investment Bank") === true ||
                    institutionSubTypes.includes("Other") === true ||
                    institutionSubTypes.includes("Sovereign Wealth Fund") ===
                        true ||
                    institutionSubTypes.includes("Asset Manager") === true ||
                    institutionSubTypes.includes("Family Office") === true ||
                    institutionSubTypes.includes(
                        "Investment Fund / Facility"
                    ) === true ||
                    institutionSubTypes.includes("Investment Manager") ===
                        true ||
                    institutionSubTypes.includes("Pension Fund") === true ||
                    institutionSubTypes.includes(
                        "Private Equity / Venture Capital Firm"
                    ) === true ||
                    institutionSubTypes.includes("Consulting Firm") === true ||
                    institutionSubTypes.includes("Deal / Financial Sponsor") ===
                        true ||
                    institutionSubTypes.includes("Financial Advisory Firm") ===
                        true ||
                    institutionSubTypes.includes("Legal Firm") === true ||
                    institutionSubTypes.includes("Project Developer") ===
                        true ||
                    institutionSubTypes.includes("Other") === true ||
                    institutionSubTypes.includes("Individual") === true
                );
            }
        }
    },
    {
        id: "f07e524d-da1f-4d50-900e-3d33f9b2a1c5",
        name: "Deal Sponsors",
        details: [
            {
                name: "Visibility and Networking",
                description:
                    "Convergence provides deal sponsors with increased visibility among top tier investors and funders. The convergence platform allows sponsors to connect directly with aligned investors and send secure messages. Similarly, through in-person and virtual channels, Convergence provides opportunities for sponsors to network with stakeholders including peers and investors, spanning the public, private, and philanthropic sectors.",
                features: [
                    "View profiles of Convergence members, their interests and focus areas",
                    "Secure messaging with investors and deal sponsors",
                    "Bespoke Convergence support facilitating connections between deals/members on request",
                    "Quarterly Convergence member meetings and events taking place across regions",
                    "Event tracker of events relevant to blended finance",
                    "Additional customized services on request"
                ]
            },
            {
                name: "Data and Intelligence",
                description:
                    "Convergence offers deal sponsors the data and intelligence they need to benchmark themselves and identify investors. Convergence maintains the largest and most detailed database of past blended finance transactions and draws analysis and insights from this database on a continuous basis. Convergence case studies profile blended finance transactions, distilling best practices and lessons which help bolster a deal sponsors investment case.",
                features: [
                    "Access to data on past blended finance transactions, including impact targets, impact approach, blending approach, and investors in the transaction",
                    "Custom report to identify aligned investors based on historical deal database",
                    "Review of member's past blended finance activity to ensure it is captured in Convergence database",
                    "Blended finance investment trend data, for Convergence members that deploy capital",
                    "Quarterly briefings providing critical analysis and insights into blended finance deal trends",
                    "Analytical tools to evaluate deal trends (e.g., for specific SDGs, regions, blending approach)",
                    "Quarterly Convergence-authored case studies on blended finance transactions",
                    "Curated knowledge library with Convergence publications as well as key industry publications",
                    "Knowledge and data recommendations based on member interests and preferences",
                    "Quarterly webinars on select topics",
                    "Monthly newsletter with latest membership news and activity",
                    "Additional customized services on request"
                ]
            },
            {
                name: "Learning and Capacity",
                description:
                    "Convergence supports deal sponsors to: i) understand the potential of blended finance to support in their deal structuring and fundraising objectives and ii) keep abreast of key blended finance trends and developments that can help bolster their investment case.",
                features: [
                    "In-depth, on-boarding meeting to ascertain member's interests and needs for blended finance",
                    "Monthly conference call with member to provide update on blended finance developments, discuss organization's current blended finance transactions and fundraising efforts and general interests/needs related to blended finance",
                    "Access to online educational modules on blended finance",
                    "Early access or preferential rates for blended finance Executive Education programs at partner Universities",
                    "Additional customized services on request"
                ]
            }
        ],
        requirements: {
            roles: ["ROLE_DEAL_SPONSOR"],
            institution_type: () => {
                return true;
            },
            institution_sub_type: () => {
                return true;
            }
        }
    },
    {
        id: "e3043ab7-493f-4ef2-93f5-74d5a0a8e5a8",
        name: "General Practitioner",
        details: [
            {
                name: "Intelligence Subscription Products",
                description:
                    "Convergence offers subscribers  the data and intelligence they need on the blended finance marketplace for their research or business needs. Convergence maintains the largest and most detailed database of past blended finance transactions and draws analysis and insights from this database on a continuous basis. Convergence case studies profile blended finance transactions, distilling best practices and lessons.",
                features: [
                    "Access to data on past blended finance transactions, including impact targets, impact approach, blending approach, and investors in the transaction",
                    "Quarterly briefings providing critical analysis and insights into blended finance deal trends",
                    "Analytical tools to analyze deal trends (e.g., for specific SDGs, regions, blending approach)",
                    "Quarterly Convergence-authored case studies on blended finance transactions",
                    "Curated knowledge library with Convergence publications as well as key industry publication",
                    "Early access or preferential rates for blended finance executive education programs at partner Universities",
                    "View profiles of Convergence members and their interests and focus areas",
                    "Quarterly Convergence member meetings and events",
                    "Event tracker of events relevant to blended finance",
                    "Additional customized services on request"
                ]
            }
        ],
        requirements: {
            roles: ["ROLE_INTELLIGENCE"],
            institution_type: () => {
                return true;
            },
            institution_sub_type: () => {
                return true;
            }
        }
    }
];

export const ELIGIBILITY = {
    [ELIGIBILITY_GROUP_0]: {
        plans: [],
        roles: [],
        terms_and_conditions: ["membership_acknowledgement"],
        requirements: {
            invests_or_provides_funding: null,
            capital_types: null,
            eligible_country: null,
            institution_type: null,
            is_fundraising: null
        }
    },
    [ELIGIBILITY_GROUP_1]: {
        plans: ["plan_one", "plan_two", "plan_four"],
        roles: ["ROLE_INVESTOR", "ROLE_MESSAGE"],
        terms_and_conditions: [
            "membership_acknowledgement",
            "country_investor_eligibility_checklist",
            "investor_registration_agreement"
        ],
        requirements: {
            invests_or_provides_funding: true,
            capital_types: (capitalTypes) => {
                if (!capitalTypes) {
                    return false;
                }

                return (
                    (capitalTypes.includes("Debt") === true ||
                        capitalTypes.includes("Equity") === true) === true
                );
            },
            eligible_country: true,
            institution_type: null,
            is_fundraising: false
        }
    },
    [ELIGIBILITY_GROUP_2]: {
        plans: ["plan_one", "plan_two"],
        roles: ["ROLE_INVESTOR", "ROLE_MESSAGE"],
        terms_and_conditions: [
            "membership_acknowledgement",
            "investor_registration_agreement",
            "dfi_registration_agreement"
        ],
        requirements: {
            invests_or_provides_funding: true,
            capital_types: (capitalTypes) => {
                if (!capitalTypes) {
                    return false;
                }
                return (
                    (capitalTypes.includes("Debt") === true ||
                        capitalTypes.includes("Equity") === true) === true
                );
            },
            eligible_country: false,
            institution_type: (institutionTypes) => {
                if (!institutionTypes) {
                    return false;
                }

                return (
                    (institutionTypes.includes("Government Entity") === true ||
                        institutionTypes.includes("Multilateral Entity") ===
                            true) === true
                );
            },
            is_fundraising: false
        }
    },
    [ELIGIBILITY_GROUP_3]: {
        plans: ["plan_one", "plan_two", "plan_four"],
        roles: ["ROLE_INTELLIGENCE"],
        terms_and_conditions: [
            "membership_acknowledgement",
            "general_registration_agreement"
        ],
        requirements: {
            invests_or_provides_funding: true,
            capital_types: (capitalTypes) => {
                if (!capitalTypes) {
                    return false;
                }

                return (
                    (capitalTypes.includes("Debt") === true ||
                        capitalTypes.includes("Equity") === true) === true
                );
            },
            eligible_country: false,
            institution_type: (institutionTypes) => {
                if (!institutionTypes) {
                    return false;
                }

                return (
                    (institutionTypes.includes("Government Entity") === true ||
                        institutionTypes.includes("Multilateral Entity") ===
                            true) === false
                );
            },
            is_fundraising: false
        }
    },
    [ELIGIBILITY_GROUP_4]: {
        plans: ["plan_one", "plan_two", "plan_four"],
        roles: ["ROLE_INVESTOR", "ROLE_MESSAGE"],
        terms_and_conditions: [
            "membership_acknowledgement",
            "non_investment_eligibility_checklist",
            "non_investment_registration_agreement"
        ],
        requirements: {
            invests_or_provides_funding: true,
            capital_types: (capitalTypes) => {
                if (!capitalTypes) {
                    return false;
                }

                return (
                    (capitalTypes.includes("Insurance") === true ||
                        capitalTypes.includes("Guarantees") === true ||
                        capitalTypes.includes("Grants") === true) &&
                    (capitalTypes.includes("Debt") === true ||
                        capitalTypes.includes("Equity") === true) === false
                );
            },
            eligible_country: null,
            institution_type: null,
            is_fundraising: false
        }
    },
    [ELIGIBILITY_GROUP_5]: {
        plans: ["plan_one", "plan_two", "plan_three", "plan_four"],
        roles: ["ROLE_INVESTOR", "ROLE_MESSAGE"],
        terms_and_conditions: [
            "membership_acknowledgement",
            "country_investor_eligibility_checklist",
            "investor_registration_agreement"
        ],
        requirements: {
            invests_or_provides_funding: true,
            capital_types: (capitalTypes) => {
                if (!capitalTypes) {
                    return false;
                }

                return (
                    (capitalTypes.includes("Debt") === true ||
                        capitalTypes.includes("Equity") === true) === true
                );
            },
            eligible_country: true,
            institution_type: null,
            is_fundraising: true
        }
    },
    [ELIGIBILITY_GROUP_6]: {
        plans: ["plan_one", "plan_two", "plan_three"],
        roles: ["ROLE_INVESTOR", "ROLE_MESSAGE"],
        terms_and_conditions: [
            "membership_acknowledgement",
            "investor_registration_agreement",
            "dfi_registration_agreement"
        ],
        requirements: {
            invests_or_provides_funding: true,
            capital_types: (capitalTypes) => {
                if (!capitalTypes) {
                    return false;
                }

                return (
                    (capitalTypes.includes("Debt") === true ||
                        capitalTypes.includes("Equity") === true) === true
                );
            },
            eligible_country: false,
            institution_type: (institutionTypes) => {
                if (!institutionTypes) {
                    return false;
                }

                return (
                    (institutionTypes.includes("Government Entity") === true ||
                        institutionTypes.includes("Multilateral Entity") ===
                            true) === true
                );
            },
            is_fundraising: true
        }
    },
    [ELIGIBILITY_GROUP_7]: {
        plans: ["plan_one", "plan_two", "plan_three", "plan_four"],
        roles: ["ROLE_DEAL_SPONSOR"],
        terms_and_conditions: [
            "membership_acknowledgement",
            "general_registration_agreement"
        ],
        requirements: {
            invests_or_provides_funding: true,
            capital_types: (capitalTypes) => {
                if (!capitalTypes) {
                    return false;
                }

                return (
                    (capitalTypes.includes("Debt") === true ||
                        capitalTypes.includes("Equity") === true) === true
                );
            },
            eligible_country: false,
            institution_type: (institutionTypes) => {
                if (!institutionTypes) {
                    return false;
                }

                return (
                    (institutionTypes.includes("Government Entity") === true ||
                        institutionTypes.includes("Multilateral Entity") ===
                            true) === false
                );
            },
            is_fundraising: true
        }
    },
    [ELIGIBILITY_GROUP_8]: {
        plans: ["plan_one", "plan_two", "plan_three", "plan_four"],
        roles: ["ROLE_INVESTOR", "ROLE_MESSAGE"],
        terms_and_conditions: [
            "membership_acknowledgement",
            "non_investment_eligibility_checklist",
            "non_investment_registration_agreement"
        ],
        requirements: {
            invests_or_provides_funding: true,
            capital_types: (capitalTypes) => {
                if (!capitalTypes) {
                    return false;
                }

                return (
                    (capitalTypes.includes("Insurance") === true ||
                        capitalTypes.includes("Guarantees") === true ||
                        capitalTypes.includes("Grants") === true) &&
                    (capitalTypes.includes("Debt") === true ||
                        capitalTypes.includes("Equity") === true) === false
                );
            },
            eligible_country: null,
            institution_type: null,
            is_fundraising: true
        }
    },
    [ELIGIBILITY_GROUP_9]: {
        plans: ["plan_one", "plan_two", "plan_three"],
        roles: ["ROLE_DEAL_SPONSOR"],
        terms_and_conditions: [
            "membership_acknowledgement",
            "general_registration_agreement"
        ],
        requirements: {
            invests_or_provides_funding: false,
            capital_types: null,
            eligible_country: null,
            institution_type: null,
            is_fundraising: true
        }
    },
    [ELIGIBILITY_GROUP_10]: {
        plans: ["plan_one", "plan_two"],
        roles: ["ROLE_INTELLIGENCE"],
        terms_and_conditions: [
            "membership_acknowledgement",
            "general_registration_agreement"
        ],
        requirements: {
            invests_or_provides_funding: false,
            capital_types: null,
            eligible_country: null,
            institution_type: null,
            is_fundraising: false
        }
    }
};

export const TYPES_OF_CAPITAL = [
    "Debt",
    "Equity",
    "Insurance",
    "Guarantees",
    "Grants"
];

export const DEAL_TYPES = [
    "Bond / Note",
    "Company",
    "Facility",
    "Fund",
    "Impact Bond",
    "Project"
];

export const KNOWLEDGE_TYPES = [
    "Case Studies",
    "Data Briefs",
    "Policy and Research Reports",
    "Primers and Toolkits"
];

export const LEARNING_TYPES = ["Training", "Webinar", "Event"];
export const LEARNING_CATEGORIES = [
    "Learning Programs",
    "Inside The Deal",
    "Deal Connect",
    "Thematic Webinars"
];

export const INSTITUTION_TYPES = {
    "Association / Network": [
        "Industry / Trade Association",
        "Informal Association / Network",
        "Member Network"
    ],
    Business: [
        "Social Enterprise",
        "Small / Medium-Sized Enterprise",
        "Large / International Corporation",
        "Other"
    ],
    "Charitable Organization": [
        "Faith-based NGO / Non-profit",
        "International NGO / Non-profit",
        "National NGO / Non-profit"
    ],
    "Endowment / Foundation": [
        "Corporate Foundation",
        "Endowment Fund",
        "Private Foundation"
    ],
    "Financial Institution": [
        "Commercial Bank",
        "Diversified Financial Institution",
        "Finance Company (non-deposit taking)",
        "Insurance Company",
        "Investment Bank",
        "Other"
    ],
    "Government Entity": [
        "Government Aid Agency",
        "Government Department / Ministry",
        "Government Fund / Programme",
        "National Development Finance Institution",
        "Public Agency / Enterprise",
        "Sovereign Wealth Fund",
        "Subnational Government Entity"
    ],
    "Investment Management Company": [
        "Asset Manager",
        "Family Office",
        "Investment Manager",
        "Investment Fund / Facility",
        "Pension Fund",
        "Private Equity / Venture Capital Firm"
    ],
    "Multilateral Entity": [
        "Multilateral Development Bank",
        "Multilateral Fund / Programme",
        "Multilateral Organization"
    ],
    "Research Institute": ["Think Tank", "University"],
    "Service Provider": [
        "Deal / Financial Sponsor",
        "Financial Advisory Firm",
        "Legal Firm",
        "Consulting Firm",
        "Project Developer",
        "Other"
    ],
    Other: ["Individual", "Other"]
};

export const CAPITAL_BENEFICIARY = [
    "Individuals / customers",
    "Early-stage / venture",
    "Small / medium enterprises",
    "Financial institutions",
    "Large companies / corporations"
];

export const GROUPED_REPORTING_CATEGORIES = {
    "Gender disaggregated": ["Gender disaggregated"],
    Reporting: ["Annual", "Endline / Final", "Baseline", "Quarter", "Mid-Term"],
    Unknown: ["Unknown"],
    "Not reporting": ["None"]
};

export const GROUPED_DEVELOPMENT_TARGETS = {
    "1: No Poverty": [
        "Climate Action and Resiliency",
        "Community Empowerment",
        "Income Growth and Employment"
    ],
    "2: Zero Hunger": [
        "Biodiversity Conservation",
        "Child and Maternal Care",
        "Climate Action and Resiliency",
        "Smallholder Farming",
        "Sustainable Agriculture and Land Use"
    ],
    "3: Good Health & Well-Being": [
        "Access to Health Services",
        "Child and Maternal Care",
        "Communicable Disease Prevention and Response",
        "Mental Health and Non-Communicable Diseases",
        "Pollution and Waste Reduction",
        "Transportation and Road Safety"
    ],
    "4: Quality Education": [
        "Access to Education",
        "Gender Equality",
        "Income Growth and Employment",
        "Training and Vocational Skills",
        "Women's Empowerment"
    ],
    "5: Gender Equality": [
        "Child and Maternal Care",
        "Gender Equality",
        "Women's Empowerment"
    ],
    "6: Clean Water & Sanitation": [
        "Fisheries and Aquatic Sustainability",
        "Pollution and Waste Reduction"
    ],
    "7: Affordable & Clean Energy": [
        "Access to Clean Energy",
        "Energy Efficiency"
    ],
    "8: Decent Work & Economic Growth": [
        "Eco-tourism",
        "Financial Inclusion",
        "Human Rights Protection / Expansion",
        "Income Growth and Employment",
        "Policy and Governance",
        "SME Growth",
        "Sustainable Consumption and Production"
    ],
    "9: Industry, Innovation, & Infrastructure": [
        "Digital Infrastructure",
        "Green Building",
        "Income Growth and Employment",
        "Physical Infrastructure",
        "SME Growth"
    ],
    "10: Reduced Inequalities": [
        "Community Empowerment",
        "Income Growth and Employment",
        "Policy and Governance"
    ],
    "11: Sustainable Cities": [
        "Affordable Housing",
        "Climate Action and Resiliency",
        "Green Building",
        "Pollution and Waste Reduction",
        "Transportation and Road Safety"
    ],
    "12: Responsible Consumption": [
        "Access to Information",
        "Fisheries and Aquatic Sustainability",
        "Pollution and Waste Reduction",
        "Sustainable Agriculture and Land Use",
        "Sustainable Consumption and Production"
    ],
    "13: Climate Action": ["Climate Action and Resiliency"],
    "14: Life Below Water": [
        "Fisheries and Aquatic Sustainability",
        "Pollution and Waste Reduction"
    ],
    "15: Life on Land": [
        "Biodiversity Conservation",
        "Fisheries and Aquatic Sustainability",
        "Sustainable Agriculture and Land Use"
    ],
    "16: Peace, Justice & Strong Institutions": [
        "Community Empowerment",
        "Human Rights Protection / Expansion",
        "Policy and Governance"
    ],
    "17: Partnerships for the Goals": ["Financial Inclusion"]
};

export const SDGS = [
    "01: No Poverty",
    "02: Zero Hunger",
    "03: Good Health & Well-Being",
    "04: Quality Education",
    "05: Gender Equality",
    "06: Clean Water & Sanitation",
    "07: Affordable & Clean Energy",
    "08: Decent Work & Economic Growth",
    "09: Industry, Innovation, & Infrastructure",
    "10: Reduced Inequalities",
    "11: Sustainable Cities",
    "12: Responsible Consumption",
    "13: Climate Action",
    "14: Life Below Water",
    "15: Life on Land",
    "16: Peace, Justice & Strong Institutions",
    "17: Partnerships for the Goals"
];

export const IMPACT_OBJECTIVES = {
    "Access to Energy": "Climate Change, Environment, and Energy",
    "Biodiversity Conservation": "Climate Change, Environment, and Energy",
    "Climate / Household Resilience": "Climate Change, Environment, and Energy",
    "Energy & Fuel Efficiency": "Climate Change, Environment, and Energy",
    "Natural Resource Conservation": "Climate Change, Environment, and Energy",
    "Sustainable Energy": "Climate Change, Environment, and Energy",

    "Access to Financial Services": "Economic Development and Inclusive Growth",
    "Capital Market Development": "Economic Development and Inclusive Growth",
    "Community Development": "Economic Development and Inclusive Growth",
    "Employment Generation": "Economic Development and Inclusive Growth",
    "Income / Productivity Growth": "Economic Development and Inclusive Growth",
    "Infrastructure Development": "Economic Development and Inclusive Growth",
    "Trade Promotion": "Economic Development and Inclusive Growth",

    "Agricultural Productivity": "Food Security",
    "Food Security": "Food Security",
    "Sustainable Land Use": "Food Security",

    "Gender Equity": "Gender",
    "Women's Empowerment": "Gender",

    "Environmental Policies & Performance":
        "Good Governance and Responsible Investing",
    "Governance & Ownership": "Good Governance and Responsible Investing",
    "Social Policies & Performance":
        "Good Governance and Responsible Investing",

    "Access to Education": "Health and Education",
    "Access to Health Services": "Health and Education",
    "Capacity Building": "Health and Education",
    "Disease Specific Prevention & Mitigation": "Health and Education",
    "Health Improvement": "Health and Education",

    "Access to Information": "Human Rights and Well-Being",
    "Affordable Housing": "Human Rights and Well-Being",
    "Emergency Humanitarian Relief": "Human Rights and Well-Being",
    "Equality & Employment / Minorities": "Human Rights and Well-Being",
    "Human Rights Protection / Expansion": "Human Rights and Well-Being",

    "Access to Clean Water & Sanitation": "Water, Sanitation, and Hygiene",
    "Pollution Prevention & Waste Management": "Water, Sanitation, and Hygiene",
    "Water Resources Management": "Water, Sanitation, and Hygiene",

    "SME Finance": "SME Finance"
};

export const SECTORS_AND_SUB_SECTORS = {
    "Agriculture Finance": "Agriculture",
    "Agro-Forestry": "Agriculture",
    "Agricultural Inputs / Farm Productivity": "Agriculture",
    "Agro-Processing": "Agriculture",
    "Fisheries and Aquaculture": "Agriculture",
    "Climate Resilient / Sustainable Agriculture": "Agriculture",

    "Education Finance": "Education",
    "Workforce Development / Skills Training": "Education",
    "Adult / Continuing Education": "Education",
    "Early Childhood / Primary Education": "Education",
    "Secondary / Tertiary Education": "Education",

    "Off-Grid Energy": "Energy",
    "Renewable Energy": "Energy",
    "Carbon Credits": "Energy",
    "Energy Efficiency / Emissions Reduction": "Energy",
    "Large-scale Power Generation": "Energy",
    "Non-Renewable Energy": "Energy",
    "Power Transmission and Distribution": "Energy",

    "Capital Market": "Financial Services",
    "Green Finance": "Financial Services",
    Insurance: "Financial Services",
    Leasing: "Financial Services",
    "Microfinance / Retail Banking": "Financial Services",
    "Small Business / Corporate Banking": "Financial Services",

    "Family Planning / Maternal Care": "Health",
    "Health Services": "Health",
    "Pharmaceuticals and Vaccinations": "Health",

    "Housing Finance": "Housing and Real Estate",
    "Commercial Real Estate": "Housing and Real Estate",

    "Commodity Finance": "Industry and Trade",
    Manufacturing: "Industry and Trade",
    Services: "Industry and Trade",
    Tourism: "Industry and Trade",
    "Trade Finance": "Industry and Trade",

    "Water Infrastructure": "Infrastructure (Non-Energy)",
    "Waste Management Infrastructure": "Infrastructure (Non-Energy)",
    "Transportation Infrastructure": "Infrastructure (Non-Energy)",
    "Telecommunication Infrastructure": "Infrastructure (Non-Energy)",
    "Critical Infrastructure": "Infrastructure (Non-Energy)",

    General: "General",
    Other: "Other"
};

export const INSTRUMENTS_AND_SUB_INSTRUMENTS_ORDER = {
    "Senior Debt": 1,
    "Convertible Debt": 2,
    "Subordinate / Mezzanine Debt": 3,
    "Concessional / First-loss Debt": 4,

    "Senior Equity": 5,
    "Junior Equity": 6,
    "First-loss Equity": 7,

    "Credit Insurance": 8,
    "Currency Swap": 9,
    "Guarantee on Debt/Equity in Capital Structure": 10,
    "Interest Rate Swap": 11,
    "Loan/Portfolio Guarantee": 12,
    "Political Risk Insurance": 13,

    "Investment-stage Grant": 14,
    "Design-stage Grant": 15,
    "Technical Assistance Grant": 16
};

export const INSTRUMENTS_AND_SUB_INSTRUMENTS = {
    "Senior Debt": "Debt",
    "Convertible Debt": "Debt",
    "Subordinate / Mezzanine Debt": "Debt",
    "Concessional / First-loss Debt": "Debt",

    "Senior Equity": "Equity",
    "Junior Equity": "Equity",
    "First-loss Equity": "Equity",

    "Credit Insurance": "Risk Mitigation",
    "Currency Swap": "Risk Mitigation",
    "Guarantee on Debt/Equity in Capital Structure": "Risk Mitigation",
    "Interest Rate Swap": "Risk Mitigation",
    "Loan/Portfolio Guarantee": "Risk Mitigation",
    "Political Risk Insurance": "Risk Mitigation",

    "Investment-stage Grant": "Grant",
    "Design-stage Grant": "Grant",
    "Technical Assistance Grant": "Grant"
};

export const DEAL_SUB_INSTRUMENT_MAPPING = {
    senior_debt: "Senior Debt",
    convertible_debt: "Convertible Debt",
    subordinate_mezzanine_debt: "Subordinate / Mezzanine Debt",
    concessional_first_loss_debt: "Concessional / First-loss Debt",
    preferred_equity: "Preferred Equity",
    common_equity: "Common Equity",
    concessional_first_loss_equity: "Concessional / First-loss Equity",
    credit_insurance: "Credit Insurance",
    currency_swap: "Currency Swap",
    guarantee_debt_equity_capital_structure:
        "Guarantee on Debt/Equity in Capital Structure",
    interest_rate_swap: "Interest Rate Swap",
    loan_portfolio_guarantee: "Loan/Portfolio Guarantee",
    political_risk_insurance: "Political Risk Insurance",
    investment_stage_grant: "Investment-stage Grant",
    design_stage_grant: "Design-stage Grant",
    technical_assistance_grant: "Technical Assistance Grant"
};
export const PARENT_INSTITUTION_TYPES = Object.keys(INSTITUTION_TYPES);

export const HEAR_ABOUT_US_OPTIONS = [
    "Referred by Convergence Member",
    "Referred by Convergence Staff",
    "Google",
    "Conference",
    "Convergence Report",
    "Convergence Newsletter",
    "Article",
    "Blended Finance E-learning course",
    "Word of Mouth",
    "Job Board",
    "Social Media",
    "Other"
];

export const BLENDING_APPROACHES = [
    "Public or philanthropic investors are concessional within the capital structure",
    "Public or philanthropic investors provide guarantees or insurance",
    "Transaction is associated with a grant-funded technical assistance facility",
    "Transaction design or preparation is grant funded"
];

export const BLENDING_ARCHETYPES = [
    "Concessional Capital",
    "Guarantee / Risk Insurance",
    "Design-Stage Grant",
    "Technical Assistance Fund"
];

export const CLIMATE_APPROACHES = ["Adaptation", "Mitigation", "Hybrid"];

export const SPECIAL_INITIATIVES = ["climate", "thk", "scale", "wash"];

export const ENGAGEMENT_STATUS = [
    "Investment complete",
    "Verbal commitment to invest",
    "Letter of investment intent",
    "Investment committee approval letter",
    "Mandated for due diligence"
];

export const GRANT_STATUS = ["In progress", "Complete"];

export const DESIGN_ACTIVITIES = [
    "Feasibility Study",
    "Proof of Concept",
    "Expansion Grant"
];

export const ELIGIBLE_COUNTRIES_AND_STATES = [
    { country: "Australia", states: null },
    { country: "Belgium", states: null },
    { country: "Brazil", states: null },
    { country: "Canada", states: ["Ontario", "Quebec"] },
    { country: "Colombia", states: null },
    { country: "Chile", states: null },
    { country: "Denmark", states: null },
    { country: "France", states: null },
    { country: "Germany", states: null },
    { country: "Hong Kong SAR, China", states: null },
    { country: "India", states: null },
    { country: "Ireland", states: null },
    { country: "Japan", states: null },
    { country: "Kenya", states: null },
    { country: "Luxembourg", states: null },
    { country: "Mauritius", states: null },
    { country: "Mexico", states: null },
    { country: "Netherlands", states: null },
    { country: "Nigeria", states: null },
    { country: "Norway", states: null },
    { country: "Peru", states: null },
    { country: "Philippines", states: null },
    { country: "Singapore", states: null },
    { country: "South Africa", states: null },
    { country: "Sweden", states: null },
    { country: "Switzerland", states: null },
    { country: "United Kingdom", states: null },
    { country: "United States", states: null }
];

export const REGION_COUNTRIES_STATES: Record<
    string,
    {
        region: string;
        country: string;
        states: Record<string, string> | null;
        subregions: string[];
    }
> = {
    // Caribbean
    AG: {
        region: "Latin America and the Caribbean",
        country: "Antigua & Barbuda",
        states: null,
        subregions: ["Caribbean"]
    },
    AW: {
        region: "Latin America and the Caribbean",
        country: "Aruba",
        states: null,
        subregions: ["Caribbean"]
    },
    BS: {
        region: "Latin America and the Caribbean",
        country: "Bahamas",
        states: null,
        subregions: ["Caribbean"]
    },
    BB: {
        region: "Latin America and the Caribbean",
        country: "Barbados",
        states: null,
        subregions: ["Caribbean"]
    },
    BM: {
        region: "North America",
        country: "Bermuda",
        states: null,
        subregions: ["Caribbean"]
    },
    VG: {
        region: "Latin America and the Caribbean",
        country: "British Virgin Islands",
        states: null,
        subregions: ["Caribbean"]
    },
    KY: {
        region: "Latin America and the Caribbean",
        country: "Cayman Islands",
        states: null,
        subregions: ["Caribbean"]
    },
    CU: {
        region: "Latin America and the Caribbean",
        country: "Cuba",
        states: null,
        subregions: ["Caribbean"]
    },
    CW: {
        region: "Latin America and the Caribbean",
        country: "Curacao",
        states: null,
        subregions: ["Caribbean"]
    },
    DM: {
        region: "Latin America and the Caribbean",
        country: "Dominica",
        states: null,
        subregions: ["Caribbean"]
    },
    DO: {
        region: "Latin America and the Caribbean",
        country: "Dominican Republic",
        states: null,
        subregions: ["Caribbean"]
    },
    GD: {
        region: "Latin America and the Caribbean",
        country: "Grenada",
        states: null,
        subregions: ["Caribbean"]
    },
    HT: {
        region: "Latin America and the Caribbean",
        country: "Haiti",
        states: null,
        subregions: ["Caribbean"]
    },
    JM: {
        region: "Latin America and the Caribbean",
        country: "Jamaica",
        states: null,
        subregions: ["Caribbean"]
    },
    PR: {
        region: "Latin America and the Caribbean",
        country: "Puerto Rico",
        states: null,
        subregions: ["Caribbean"]
    },
    SX: {
        region: "Latin America and the Caribbean",
        country: "Sint Maarten",
        states: null,
        subregions: ["Caribbean"]
    },
    KN: {
        region: "Latin America and the Caribbean",
        country: "St. Kitts & Nevis",
        states: null,
        subregions: ["Caribbean"]
    },
    LC: {
        region: "Latin America and the Caribbean",
        country: "St. Lucia",
        states: null,
        subregions: ["Caribbean"]
    },
    MF: {
        region: "Latin America and the Caribbean",
        country: "St. Martin",
        states: null,
        subregions: ["Caribbean"]
    },
    VC: {
        region: "Latin America and the Caribbean",
        country: "St. Vincent & the Grenadines",
        states: null,
        subregions: ["Caribbean"]
    },
    TT: {
        region: "Latin America and the Caribbean",
        country: "Trinidad & Tobago",
        states: null,
        subregions: ["Caribbean"]
    },
    TC: {
        region: "Latin America and the Caribbean",
        country: "Turks & Caicos Islands",
        states: null,
        subregions: ["Caribbean"]
    },
    VI: {
        region: "Latin America and the Caribbean",
        country: "Virgin Islands",
        states: null,
        subregions: ["Caribbean"]
    },

    // Central Africa
    AO: {
        region: "Sub-Saharan Africa",
        country: "Angola",
        states: null,
        subregions: ["Central Africa"]
    },
    CM: {
        region: "Sub-Saharan Africa",
        country: "Cameroon",
        states: null,
        subregions: ["Central Africa"]
    },
    CF: {
        region: "Sub-Saharan Africa",
        country: "Central African Republic",
        states: null,
        subregions: ["Central Africa"]
    },
    TD: {
        region: "Sub-Saharan Africa",
        country: "Chad",
        states: null,
        subregions: ["Central Africa"]
    },
    CD: {
        region: "Sub-Saharan Africa",
        country: "Congo, Dem. Rep.",
        states: null,
        subregions: ["Central Africa"]
    },
    GQ: {
        region: "Sub-Saharan Africa",
        country: "Equatoria Guinea",
        states: null,
        subregions: ["Central Africa"]
    },
    GA: {
        region: "Sub-Saharan Africa",
        country: "Gabon",
        states: null,
        subregions: ["Central Africa"]
    },
    CG: {
        region: "Sub-Saharan Africa",
        country: "Congo, Rep.",
        states: null,
        subregions: ["Central Africa"]
    },
    ST: {
        region: "Sub-Saharan Africa",
        country: "Sao Tome & Principe",
        states: null,
        subregions: ["Central Africa"]
    },

    // Central America
    BZ: {
        region: "Latin America and the Caribbean",
        country: "Belize",
        states: null,
        subregions: ["Central America"]
    },
    CR: {
        region: "Latin America and the Caribbean",
        country: "Costa Rica",
        states: null,
        subregions: ["Central America"]
    },
    SV: {
        region: "Latin America and the Caribbean",
        country: "El Salvador",
        states: null,
        subregions: ["Central America"]
    },
    GT: {
        region: "Latin America and the Caribbean",
        country: "Guatemala",
        states: null,
        subregions: ["Central America"]
    },
    HN: {
        region: "Latin America and the Caribbean",
        country: "Honduras",
        states: null,
        subregions: ["Central America"]
    },
    MX: {
        region: "Latin America and the Caribbean",
        country: "Mexico",
        states: null,
        subregions: ["Central America"]
    },
    NI: {
        region: "Latin America and the Caribbean",
        country: "Nicaragua",
        states: null,
        subregions: ["Central America"]
    },
    PA: {
        region: "Latin America and the Caribbean",
        country: "Panama",
        states: null,
        subregions: ["Central America"]
    },

    // Central Asia
    KZ: {
        region: "Europe and Central Asia",
        country: "Kazakhstan",
        states: null,
        subregions: ["Central Asia"]
    },
    KG: {
        region: "Europe and Central Asia",
        country: "Kyrgyz Republic",
        states: null,
        subregions: ["Central Asia"]
    },
    TJ: {
        region: "Europe and Central Asia",
        country: "Tajikistan",
        states: null,
        subregions: ["Central Asia"]
    },
    TM: {
        region: "Europe and Central Asia",
        country: "Turkmenistan",
        states: null,
        subregions: ["Central Asia"]
    },
    UZ: {
        region: "Europe and Central Asia",
        country: "Uzbekistan",
        states: null,
        subregions: ["Central Asia"]
    },

    // East Africa
    BI: {
        region: "Sub-Saharan Africa",
        country: "Burundi",
        states: null,
        subregions: ["East Africa"]
    },
    KM: {
        region: "Sub-Saharan Africa",
        country: "Comoros",
        states: null,
        subregions: ["East Africa"]
    },
    DJ: {
        region: "Middle East and North Africa",
        country: "Djibouti",
        states: null,
        subregions: ["East Africa"]
    },
    ER: {
        region: "Sub-Saharan Africa",
        country: "Eritrea",
        states: null,
        subregions: ["East Africa"]
    },
    ET: {
        region: "Sub-Saharan Africa",
        country: "Ethiopia",
        states: null,
        subregions: ["East Africa"]
    },
    KE: {
        region: "Sub-Saharan Africa",
        country: "Kenya",
        states: null,
        subregions: ["East Africa"]
    },
    MG: {
        region: "Sub-Saharan Africa",
        country: "Madagascar",
        states: null,
        subregions: ["East Africa"]
    },
    MW: {
        region: "Sub-Saharan Africa",
        country: "Malawi",
        states: null,
        subregions: ["East Africa"]
    },
    MU: {
        region: "Sub-Saharan Africa",
        country: "Mauritius",
        states: null,
        subregions: ["East Africa"]
    },
    MZ: {
        region: "Sub-Saharan Africa",
        country: "Mozambique",
        states: null,
        subregions: ["East Africa"]
    },
    RW: {
        region: "Sub-Saharan Africa",
        country: "Rwanda",
        states: null,
        subregions: ["East Africa"]
    },
    SC: {
        region: "Sub-Saharan Africa",
        country: "Seychelles",
        states: null,
        subregions: ["East Africa"]
    },
    SO: {
        region: "Sub-Saharan Africa",
        country: "Somalia",
        states: null,
        subregions: ["East Africa"]
    },
    SS: {
        region: "Sub-Saharan Africa",
        country: "South Sudan",
        states: null,
        subregions: ["East Africa"]
    },
    SD: {
        region: "Sub-Saharan Africa",
        country: "Sudan",
        states: null,
        subregions: ["East Africa"]
    },
    TZ: {
        region: "Sub-Saharan Africa",
        country: "Tanzania",
        states: null,
        subregions: ["East Africa"]
    },
    UG: {
        region: "Sub-Saharan Africa",
        country: "Uganda",
        states: null,
        subregions: ["East Africa"]
    },
    ZM: {
        region: "Sub-Saharan Africa",
        country: "Zambia",
        states: null,
        subregions: ["East Africa"]
    },
    ZW: {
        region: "Sub-Saharan Africa",
        country: "Zimbabwe",
        states: null,
        subregions: ["East Africa"]
    },

    // East Asia
    CN: {
        region: "East Asia and Pacific",
        country: "China",
        states: null,
        subregions: ["East Asia"]
    },
    HK: {
        region: "East Asia and Pacific",
        country: "Hong Kong SAR, China",
        states: null,
        subregions: ["East Asia"]
    },
    JP: {
        region: "East Asia and Pacific",
        country: "Japan",
        states: null,
        subregions: ["East Asia"]
    },
    MO: {
        region: "East Asia and Pacific",
        country: "Macao SAR, China",
        states: null,
        subregions: ["East Asia"]
    },
    MN: {
        region: "East Asia and Pacific",
        country: "Mongolia",
        states: null,
        subregions: ["East Asia"]
    },
    KP: {
        region: "East Asia and Pacific",
        country: "Korea, Dem. People's Rep.",
        states: null,
        subregions: ["East Asia"]
    },
    KR: {
        region: "East Asia and Pacific",
        country: "South Korea",
        states: null,
        subregions: ["East Asia"]
    },
    TW: {
        region: "East Asia and Pacific",
        country: "Taiwan, China",
        states: null,
        subregions: ["East Asia"]
    },

    // Eastern Europe
    AL: {
        region: "Europe and Central Asia",
        country: "Albania",
        states: null,
        subregions: ["Eastern Europe"]
    },
    BY: {
        region: "Europe and Central Asia",
        country: "Belarus",
        states: null,
        subregions: ["Eastern Europe"]
    },
    BA: {
        region: "Europe and Central Asia",
        country: "Bosnia and Herzegovina",
        states: null,
        subregions: ["Eastern Europe"]
    },
    BG: {
        region: "Europe and Central Asia",
        country: "Bulgaria",
        states: null,
        subregions: ["Eastern Europe"]
    },
    HR: {
        region: "Europe and Central Asia",
        country: "Croatia",
        states: null,
        subregions: ["Eastern Europe"]
    },
    CZ: {
        region: "Europe and Central Asia",
        country: "Czech Republic",
        states: null,
        subregions: ["Eastern Europe"]
    },
    EE: {
        region: "Europe and Central Asia",
        country: "Estonia",
        states: null,
        subregions: ["Eastern Europe"]
    },
    GR: {
        region: "Europe and Central Asia",
        country: "Greece",
        states: null,
        subregions: ["Eastern Europe"]
    },
    HU: {
        region: "Europe and Central Asia",
        country: "Hungary",
        states: null,
        subregions: ["Eastern Europe"]
    },
    XK: {
        region: "Europe and Central Asia",
        country: "Kosovo",
        states: null,
        subregions: ["Eastern Europe"]
    },
    LV: {
        region: "Europe and Central Asia",
        country: "Latvia",
        states: null,
        subregions: ["Eastern Europe"]
    },
    LT: {
        region: "Europe and Central Asia",
        country: "Lithuania",
        states: null,
        subregions: ["Eastern Europe"]
    },
    MK: {
        region: "Europe and Central Asia",
        country: "Macedonia",
        states: null,
        subregions: ["Eastern Europe"]
    },
    MD: {
        region: "Europe and Central Asia",
        country: "Moldova",
        states: null,
        subregions: ["Eastern Europe"]
    },
    ME: {
        region: "Europe and Central Asia",
        country: "Montenegro",
        states: null,
        subregions: ["Eastern Europe"]
    },
    PL: {
        region: "Europe and Central Asia",
        country: "Poland",
        states: null,
        subregions: ["Eastern Europe"]
    },
    RO: {
        region: "Europe and Central Asia",
        country: "Romania",
        states: null,
        subregions: ["Eastern Europe"]
    },
    RU: {
        region: "Europe and Central Asia",
        country: "Russian Federation",
        states: null,
        subregions: ["Eastern Europe"]
    },
    RS: {
        region: "Europe and Central Asia",
        country: "Serbia",
        states: null,
        subregions: ["Eastern Europe"]
    },
    SK: {
        region: "Europe and Central Asia",
        country: "Slovak Republic",
        states: null,
        subregions: ["Eastern Europe"]
    },
    SI: {
        region: "Europe and Central Asia",
        country: "Slovenia",
        states: null,
        subregions: ["Eastern Europe"]
    },
    UA: {
        region: "Europe and Central Asia",
        country: "Ukraine",
        states: null,
        subregions: ["Eastern Europe"]
    },

    // North Africa
    DZ: {
        region: "Middle East and North Africa",
        country: "Algeria",
        states: null,
        subregions: ["North Africa"]
    },
    EG: {
        region: "Middle East and North Africa",
        country: "Egypt",
        states: null,
        subregions: ["North Africa"]
    },
    LY: {
        region: "Middle East and North Africa",
        country: "Libya",
        states: null,
        subregions: ["North Africa"]
    },
    MA: {
        region: "Middle East and North Africa",
        country: "Morocco",
        states: null,
        subregions: ["North Africa"]
    },
    TN: {
        region: "Middle East and North Africa",
        country: "Tunisia",
        states: null,
        subregions: ["North Africa"]
    },

    // North America
    CA: {
        region: "North America",
        country: "Canada",
        states: {
            "CA-AB": "Alberta",
            "CA-BC": "British Columbia",
            "CA-MB": "Manitoba",
            "CA-NB": "New Brunswick",
            "CA-NL": "Newfoundland and Labrador",
            "CA-NS": "Nova Scotia",
            "CA-ON": "Ontario",
            "CA-PE": "Prince Edward Island",
            "CA-QC": "Quebec",
            "CA-SK": "Saskatchewan",
            "CA-NT": "Northwest Territories",
            "CA-NU": "Nunavut",
            "CA-YT": "Yukon"
        },
        subregions: ["North America"]
    },
    GL: {
        region: "Europe and Central Asia",
        country: "Greenland",
        states: null,
        subregions: ["North America"]
    },
    // TS CODE CHANGE
    US: {
        region: "North America",
        country: "United States",
        states: null,
        subregions: ["North America"]
    },

    // Oceania
    AS: {
        region: "East Asia and Pacific",
        country: "American Samoa",
        states: null,
        subregions: ["Oceania"]
    },
    AU: {
        region: "East Asia and Pacific",
        country: "Australia",
        states: null,
        subregions: ["Oceania"]
    },
    FJ: {
        region: "East Asia and Pacific",
        country: "Fiji",
        states: null,
        subregions: ["Oceania"]
    },
    PF: {
        region: "East Asia and Pacific",
        country: "French Polynesia",
        states: null,
        subregions: ["Oceania"]
    },
    GU: {
        region: "East Asia and Pacific",
        country: "Guam",
        states: null,
        subregions: ["Oceania"]
    },
    KI: {
        region: "East Asia and Pacific",
        country: "Kiribati",
        states: null,
        subregions: ["Oceania"]
    },
    MH: {
        region: "East Asia and Pacific",
        country: "Marshall Islands",
        states: null,
        subregions: ["Oceania"]
    },
    FM: {
        region: "East Asia and Pacific",
        country: "Micronesia, Fed. Sts.",
        states: null,
        subregions: ["Oceania"]
    },
    NR: {
        region: "East Asia and Pacific",
        country: "Nauru",
        states: null,
        subregions: ["Oceania"]
    },
    NC: {
        region: "East Asia and Pacific",
        country: "New Caledonia",
        states: null,
        subregions: ["Oceania"]
    },
    NZ: {
        region: "East Asia and Pacific",
        country: "New Zealand",
        states: null,
        subregions: ["Oceania"]
    },
    MP: {
        region: "East Asia and Pacific",
        country: "Northern Mariana Islands",
        states: null,
        subregions: ["Oceania"]
    },
    PW: {
        region: "East Asia and Pacific",
        country: "Palau",
        states: null,
        subregions: ["Oceania"]
    },
    PG: {
        region: "East Asia and Pacific",
        country: "Papua New Guinea",
        states: null,
        subregions: ["Oceania"]
    },
    WS: {
        region: "East Asia and Pacific",
        country: "Samoa",
        states: null,
        subregions: ["Oceania"]
    },
    SB: {
        region: "East Asia and Pacific",
        country: "Solomon Islands",
        states: null,
        subregions: ["Oceania"]
    },
    TO: {
        region: "East Asia and Pacific",
        country: "Tonga",
        states: null,
        subregions: ["Oceania"]
    },
    TV: {
        region: "East Asia and Pacific",
        country: "Tuvalu",
        states: null,
        subregions: ["Oceania"]
    },
    VU: {
        region: "East Asia and Pacific",
        country: "Vanuatu",
        states: null,
        subregions: ["Oceania"]
    },

    // South America
    AR: {
        region: "Latin America and the Caribbean",
        country: "Argentina",
        states: null,
        subregions: ["South America"]
    },
    BO: {
        region: "Latin America and the Caribbean",
        country: "Bolivia",
        states: null,
        subregions: ["South America"]
    },
    BR: {
        region: "Latin America and the Caribbean",
        country: "Brazil",
        states: null,
        subregions: ["South America"]
    },
    CL: {
        region: "Latin America and the Caribbean",
        country: "Chile",
        states: null,
        subregions: ["South America"]
    },
    CO: {
        region: "Latin America and the Caribbean",
        country: "Colombia",
        states: null,
        subregions: ["South America"]
    },
    EC: {
        region: "Latin America and the Caribbean",
        country: "Ecuador",
        states: null,
        subregions: ["South America"]
    },
    GY: {
        region: "Latin America and the Caribbean",
        country: "Guyana",
        states: null,
        subregions: ["South America"]
    },
    PY: {
        region: "Latin America and the Caribbean",
        country: "Paraguay",
        states: null,
        subregions: ["South America"]
    },
    PE: {
        region: "Latin America and the Caribbean",
        country: "Peru",
        states: null,
        subregions: ["South America"]
    },
    SR: {
        region: "Latin America and the Caribbean",
        country: "Suriname",
        states: null,
        subregions: ["South America"]
    },
    UY: {
        region: "Latin America and the Caribbean",
        country: "Uruguay",
        states: null,
        subregions: ["South America"]
    },
    VE: {
        region: "Latin America and the Caribbean",
        country: "Venezuela",
        states: null,
        subregions: ["South America"]
    },

    // South Asia
    AF: {
        region: "South Asia",
        country: "Afghanistan",
        states: null,
        subregions: ["South Asia"]
    },
    BD: {
        region: "South Asia",
        country: "Bangladesh",
        states: null,
        subregions: ["South Asia"]
    },
    BT: {
        region: "South Asia",
        country: "Bhutan",
        states: null,
        subregions: ["South Asia"]
    },
    IN: {
        region: "South Asia",
        country: "India",
        states: null,
        subregions: ["South Asia"]
    },
    IR: {
        region: "Middle East and North Africa",
        country: "Iran",
        states: null,
        subregions: ["South Asia"]
    },
    MV: {
        region: "South Asia",
        country: "Maldives",
        states: null,
        subregions: ["South Asia"]
    },
    NP: {
        region: "South Asia",
        country: "Nepal",
        states: null,
        subregions: ["South Asia"]
    },
    PK: {
        region: "South Asia",
        country: "Pakistan",
        states: null,
        subregions: ["South Asia"]
    },
    LK: {
        region: "South Asia",
        country: "Sri Lanka",
        states: null,
        subregions: ["South Asia"]
    },

    // East Asia and Pacific
    BN: {
        region: "East Asia and Pacific",
        country: "Brunei Darussalam",
        states: null,
        subregions: ["Southeast Asia"]
    },
    KH: {
        region: "East Asia and Pacific",
        country: "Cambodia",
        states: null,
        subregions: ["Southeast Asia"]
    },
    ID: {
        region: "East Asia and Pacific",
        country: "Indonesia",
        states: null,
        subregions: ["Southeast Asia"]
    },
    LA: {
        region: "East Asia and Pacific",
        country: "Lao PDR",
        states: null,
        subregions: ["Southeast Asia"]
    },
    MY: {
        region: "East Asia and Pacific",
        country: "Malaysia",
        states: null,
        subregions: ["Southeast Asia"]
    },
    MM: {
        region: "East Asia and Pacific",
        country: "Myanmar",
        states: null,
        subregions: ["Southeast Asia"]
    },
    PH: {
        region: "East Asia and Pacific",
        country: "Philippines",
        states: null,
        subregions: ["Southeast Asia"]
    },
    SG: {
        region: "East Asia and Pacific",
        country: "Singapore",
        states: null,
        subregions: ["Southeast Asia"]
    },
    TH: {
        region: "East Asia and Pacific",
        country: "Thailand",
        states: null,
        subregions: ["Southeast Asia"]
    },
    TL: {
        region: "East Asia and Pacific",
        country: "Timor-Leste",
        states: null,
        subregions: ["Southeast Asia"]
    },
    VN: {
        region: "East Asia and Pacific",
        country: "Vietnam",
        states: null,
        subregions: ["Southeast Asia"]
    },

    // Southern Africa
    BW: {
        region: "Sub-Saharan Africa",
        country: "Botswana",
        states: null,
        subregions: ["Southern Africa"]
    },
    LS: {
        region: "Sub-Saharan Africa",
        country: "Lesotho",
        states: null,
        subregions: ["Southern Africa"]
    },
    NA: {
        region: "Sub-Saharan Africa",
        country: "Namibia",
        states: null,
        subregions: ["Southern Africa"]
    },
    ZA: {
        region: "Sub-Saharan Africa",
        country: "South Africa",
        states: null,
        subregions: ["Southern Africa"]
    },
    SZ: {
        region: "Sub-Saharan Africa",
        country: "Eswatini",
        states: null,
        subregions: ["Southern Africa"]
    },

    // West Africa
    BJ: {
        region: "Sub-Saharan Africa",
        country: "Benin",
        states: null,
        subregions: ["West Africa"]
    },
    BF: {
        region: "Sub-Saharan Africa",
        country: "Burkina Faso",
        states: null,
        subregions: ["West Africa"]
    },
    CV: {
        region: "Sub-Saharan Africa",
        country: "Cabo Verde",
        states: null,
        subregions: ["West Africa"]
    },
    CI: {
        region: "Sub-Saharan Africa",
        country: "Cote d'Ivoire",
        states: null,
        subregions: ["West Africa"]
    },
    GM: {
        region: "Sub-Saharan Africa",
        country: "Gambia",
        states: null,
        subregions: ["West Africa"]
    },
    GH: {
        region: "Sub-Saharan Africa",
        country: "Ghana",
        states: null,
        subregions: ["West Africa"]
    },
    GN: {
        region: "Sub-Saharan Africa",
        country: "Guinea",
        states: null,
        subregions: ["West Africa"]
    },
    GW: {
        region: "Sub-Saharan Africa",
        country: "Guinea-Bissau",
        states: null,
        subregions: ["West Africa"]
    },
    LR: {
        region: "Sub-Saharan Africa",
        country: "Liberia",
        states: null,
        subregions: ["West Africa"]
    },
    ML: {
        region: "Sub-Saharan Africa",
        country: "Mali",
        states: null,
        subregions: ["West Africa"]
    },
    MR: {
        region: "Sub-Saharan Africa",
        country: "Mauritania",
        states: null,
        subregions: ["West Africa"]
    },
    NE: {
        region: "Sub-Saharan Africa",
        country: "Niger",
        states: null,
        subregions: ["West Africa"]
    },
    NG: {
        region: "Sub-Saharan Africa",
        country: "Nigeria",
        states: null,
        subregions: ["West Africa"]
    },
    SN: {
        region: "Sub-Saharan Africa",
        country: "Senegal",
        states: null,
        subregions: ["West Africa"]
    },
    SL: {
        region: "Sub-Saharan Africa",
        country: "Sierra Leone",
        states: null,
        subregions: ["West Africa"]
    },
    TG: {
        region: "Sub-Saharan Africa",
        country: "Togo",
        states: null,
        subregions: ["West Africa"]
    },

    // West Asia
    AM: {
        region: "Middle East and North Africa",
        country: "Armenia",
        states: null,
        subregions: ["West Asia"]
    },
    AZ: {
        region: "Europe and Central Asia",
        country: "Azerbaijan",
        states: null,
        subregions: ["West Asia"]
    },
    BH: {
        region: "Middle East and North Africa",
        country: "Bahrain",
        states: null,
        subregions: ["West Asia"]
    },
    CY: {
        region: "Europe and Central Asia",
        country: "Cyprus",
        states: null,
        subregions: ["West Asia"]
    },
    IQ: {
        region: "Middle East and North Africa",
        country: "Iraq",
        states: null,
        subregions: ["West Asia"]
    },
    IL: {
        region: "Middle East and North Africa",
        country: "Israel",
        states: null,
        subregions: ["West Asia"]
    },
    JO: {
        region: "Middle East and North Africa",
        country: "Jordan",
        states: null,
        subregions: ["West Asia"]
    },
    KW: {
        region: "Middle East and North Africa",
        country: "Kuwait",
        states: null,
        subregions: ["West Asia"]
    },
    LB: {
        region: "Middle East and North Africa",
        country: "Lebanon",
        states: null,
        subregions: ["West Asia"]
    },
    OM: {
        region: "Middle East and North Africa",
        country: "Oman",
        states: null,
        subregions: ["West Asia"]
    },
    QA: {
        region: "Middle East and North Africa",
        country: "Qatar",
        states: null,
        subregions: ["West Asia"]
    },
    SA: {
        region: "Middle East and North Africa",
        country: "Saudi Arabia",
        states: null,
        subregions: ["West Asia"]
    },
    SY: {
        region: "Middle East and North Africa",
        country: "Syrian Arab Republic",
        states: null,
        subregions: ["West Asia"]
    },
    TR: {
        region: "Middle East and North Africa",
        country: "Turkey",
        states: null,
        subregions: ["West Asia"]
    },
    AE: {
        region: "Middle East and North Africa",
        country: "United Arab Emirates",
        states: null,
        subregions: ["West Asia"]
    },
    GZS: {
        region: "Middle East and North Africa",
        country: "West Bank and Gaza",
        states: null,
        subregions: ["West Asia"]
    },
    YE: {
        region: "Middle East and North Africa",
        country: "Yemen",
        states: null,
        subregions: ["West Asia"]
    },

    // Western Europe
    AD: {
        region: "Europe and Central Asia",
        country: "Andorra",
        states: null,
        subregions: ["Western Europe"]
    },
    AT: {
        region: "Europe and Central Asia",
        country: "Austria",
        states: null,
        subregions: ["Western Europe"]
    },
    BE: {
        region: "Europe and Central Asia",
        country: "Belgium",
        states: null,
        subregions: ["Western Europe"]
    },
    CHN: {
        region: "Europe and Central Asia",
        country: "Channel Islands",
        states: null,
        subregions: ["Western Europe"]
    },
    DK: {
        region: "Europe and Central Asia",
        country: "Denmark",
        states: null,
        subregions: ["Western Europe"]
    },
    FO: {
        region: "Europe and Central Asia",
        country: "Faroe Islands",
        states: null,
        subregions: ["Western Europe"]
    },
    FI: {
        region: "Europe and Central Asia",
        country: "Finland",
        states: null,
        subregions: ["Western Europe"]
    },
    FR: {
        region: "Europe and Central Asia",
        country: "France",
        states: null,
        subregions: ["Western Europe"]
    },
    GE: {
        region: "Europe and Central Asia",
        country: "Georgia",
        states: null,
        subregions: ["Western Europe"]
    },
    DE: {
        region: "Europe and Central Asia",
        country: "Germany",
        states: null,
        subregions: ["Western Europe"]
    },
    GI: {
        region: "Europe and Central Asia",
        country: "Gibraltar",
        states: null,
        subregions: ["Western Europe"]
    },
    IS: {
        region: "Europe and Central Asia",
        country: "Iceland",
        states: null,
        subregions: ["Western Europe"]
    },
    IE: {
        region: "Europe and Central Asia",
        country: "Ireland",
        states: null,
        subregions: ["Western Europe"]
    },
    IM: {
        region: "Europe and Central Asia",
        country: "Isle of Man",
        states: null,
        subregions: ["Western Europe"]
    },
    IT: {
        region: "Europe and Central Asia",
        country: "Italy",
        states: null,
        subregions: ["Western Europe"]
    },
    LI: {
        region: "Europe and Central Asia",
        country: "Liechtenstein",
        states: null,
        subregions: ["Western Europe"]
    },
    LU: {
        region: "Europe and Central Asia",
        country: "Luxembourg",
        states: null,
        subregions: ["Western Europe"]
    },
    MT: {
        region: "Middle East and North Africa",
        country: "Malta",
        states: null,
        subregions: ["Western Europe"]
    },
    MC: {
        region: "Europe and Central Asia",
        country: "Monaco",
        states: null,
        subregions: ["Western Europe"]
    },
    NL: {
        region: "Europe and Central Asia",
        country: "Netherlands",
        states: null,
        subregions: ["Western Europe"]
    },
    NO: {
        region: "Europe and Central Asia",
        country: "Norway",
        states: null,
        subregions: ["Western Europe"]
    },
    PT: {
        region: "Europe and Central Asia",
        country: "Portugal",
        states: null,
        subregions: ["Western Europe"]
    },
    SM: {
        region: "Europe and Central Asia",
        country: "San Marino",
        states: null,
        subregions: ["Western Europe"]
    },
    ES: {
        region: "Europe and Central Asia",
        country: "Spain",
        states: null,
        subregions: ["Western Europe"]
    },
    SE: {
        region: "Europe and Central Asia",
        country: "Sweden",
        states: null,
        subregions: ["Western Europe"]
    },
    CH: {
        region: "Europe and Central Asia",
        country: "Switzerland",
        states: null,
        subregions: ["Western Europe"]
    },
    GB: {
        region: "Europe and Central Asia",
        country: "United Kingdom",
        states: null,
        subregions: ["Western Europe"]
    },

    // Global
    GBL: {
        region: "Global",
        country: "Global",
        states: null,
        subregions: ["Global"]
    }
};

export const COUNTRIES_BY_NAME = {};

Object.keys(REGION_COUNTRIES_STATES).forEach((countryCode) => {
    COUNTRIES_BY_NAME[
        REGION_COUNTRIES_STATES[countryCode]["country"]
    ] = countryCode;
});

export const GRANT_STATUS_FILTER = {
    label: "Status",
    optionTitles: ["Status"],
    options: () => GRANT_STATUS,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const DESIGN_ACTIVITIES_FILTER = {
    label: "Design Activity",
    optionTitles: ["Design Activity"],
    options: () => DESIGN_ACTIVITIES,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const CAPITAL_BENEFICIARIES_FILTER = {
    label: "Capital Beneficiary",
    optionTitles: ["Capital Beneficiary"],
    options: () => CAPITAL_BENEFICIARY,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const DEAL_TYPES_FILTER = {
    label: "Deal Type",
    optionTitles: ["Deal Type"],
    options: () => DEAL_TYPES,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};
export const KNOWLEDGE_TYPES_FILTER = {
    label: "Knowledge Type",
    optionTitles: ["Knowledge Type"],
    options: () => KNOWLEDGE_TYPES,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const LEARNING_TYPES_FILTER = {
    label: "Learning Type",
    optionTitles: ["Learning Type"],
    options: () => LEARNING_TYPES,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const SECTORS_AND_SUB_SECTORS_FILTER = {
    label: "Sector & Sub-Sector",
    optionTitles: ["Sector", "Sub-Sector (Optional)"],
    options: () => getSectorsAndSubSectors(),
    default: {},
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const SECTORS_FILTER = {
    label: "Sector",
    optionTitles: ["Sector"],
    options: () => getAllSectors(),
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const SUB_SECTORS_FILTER = {
    label: "Sub-Sector",
    optionTitles: ["Sub-Sector"],
    options: () => getAllSubSectors(),
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const REGIONS_AND_COUNTRIES_FILTER = {
    label: "Region & Country",
    optionTitles: ["Region", "Country (Optional)"],
    options: () => getRegionsAndCountries(),
    default: {},
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const COUNTRIES_FILTER = {
    label: "Country Focus",
    optionTitles: ["Country"],
    options: () => getAllCountriesArray(),
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const REGIONS_FILTER = {
    label: "Region Focus",
    optionTitles: ["Region"],
    options: () => getAllRegions(),
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const BLENDING_ARCHETYPE_FILTER = {
    label: "Blending Archetype",
    optionTitles: ["Blending Archetype"],
    options: () => BLENDING_ARCHETYPES,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const SDGS_FILTER = {
    label: "SDG Alignment",
    optionTitles: ["SDGs"],
    options: () => SDGS,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const IMPACT_THEMES_FILTER = {
    label: "Impact Theme",
    optionTitles: ["Impact Theme"],
    options: () => getAllImpactThemes(),
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const IMPACT_THEMES_AND_OBJECTIVES_FILTER = {
    label: "Impact Theme & Objective",
    optionTitles: ["Impact Theme", "Impact Objective (Optional)"],
    options: () => getImpactThemesAndObjectives(),
    default: {},
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const INVESTMENT_INSTRUMENTS_AND_SUB_INSTRUMENTS_FILTER = {
    label: "Investment Instrument",
    optionTitles: ["Investment Instrument", "Sub-Instrument (Optional)"],
    options: () => getInvestmentInstrumentsAndSubInstruments(),
    default: {},
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};
export const AUTO_SAVE_TIME = 60000;

export const DOMICILED_COUNTRIES_FILTER = {
    label: "Domiciled Country & State",
    optionTitles: ["Domiciled Country", "Domiciled State (Optional)"],
    options: () => getDomiciledCountries(),
    default: {},
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};
export const INSTITUTION_TYPES_FILTER = {
    label: "Institution Type & Sub-Type",
    optionTitles: ["Institution Type", "Institution Sub-Type (Optional)"],
    options: () => INSTITUTION_TYPES,
    default: {},
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};
export const DEPLOYING_CAPITAL_FILTER = {
    label: "Deploying Capital",
    optionTitles: ["Deploying Capital"],
    options: () => ["Yes", "No"],
    default: [],
    multiple: false,
    isEnabled: () => true,
    notEnabledMessage: ""
};
export const BLENDING_APPROACHES_FILTER = {
    label: "Blending Archetype",
    optionTitles: ["Blending Archetype"],
    options: () => BLENDING_APPROACHES,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const CLIMATE_APPROACHES_FILTER = {
    label: "Climate Approach",
    optionTitles: ["Approach"],
    options: () => CLIMATE_APPROACHES,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const SPECIAL_INITIATIVES_FILTER = {
    label: "Special Initiative",
    optionTitles: ["Special Initiative"],
    options: () => SPECIAL_INITIATIVES,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const INVESTOR_FILTER = {
    label: "Investor",
    component: SearchInvestorFilter,
    default: {},
    multiple: false,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const KEYWORD_FILTER = {
    label: "Keyword",
    component: KeywordSearchFilter,
    default: "",
    multiple: false,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const MIN_MAX_DEAL_SIZE_FILTER = {
    label: "Deal Size",
    component: MinMaxDealSizeFilter,
    default: [],
    multiple: false,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const MIN_MAX_LAUNCH_YEAR_FILTER = {
    label: "Launch Year",
    component: MinMaxLaunchYear,
    default: [],
    multiple: false,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const MIN_MAX_CLOSE_YEAR_FILTER = {
    label: "Close Year",
    component: MinMaxCloseYear,
    default: [],
    multiple: false,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const CAPITAL_DEPLOYED_FILTER = {
    label: "Type of Capital Deployed",
    optionTitles: ["Type of Capital Deployed"],
    options: () => TYPES_OF_CAPITAL,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const DESIGN_FUNDING_WINDOW_FILTER = {
    label: "Design Funding Window",
    // component: DesignFundingWindowFilter,
    default: [],
    multiple: true,
    isEnabled: () => true,
    notEnabledMessage: ""
};

export const TAG_COLOR_MAP = {
    categoryLearningPrograms: "rgb(2, 72, 229)",
    categoryInsideTheDeal: "rgb(94, 79, 254)",
    categoryDealConnect: "rgb(215, 58, 217)",
    categoryThematicWebinars: "rgb(136, 103, 28)",
    categoryBespokeWorkshops: "",
    categoryOnlineLearning: ""
};
