/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import cloneDeep from "lodash.clonedeep";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React from "react";
import FilterButton from "../general/FilterButton/FilterButton";
import FilterButtonActiveFilters from "../general/FilterButton/FilterButtonActiveFilters";
import SearchButton from "../general/SearchButton";
import SortButton from "../general/SortButton";
import theme from "../theme/3.0/theme";
import ToggleSwitch from "../theme/3.0/Form/Button/ToogleSwitch";

type Props = {
    // TS NOTE: no sort here
    // TS NOTE: no first here

    // TS TODO TYPE STRICTER
    availableFilters;
    initialFilters?;
    filters?;
    keyword?;
    updateState;
    toGraphQLFilters;

    sortButtonLabel;
    enableSortButton;
    disableSearchButton?;
    queryVariables?;
    backgroundColor?;

    groupFilters?;
    enableToggles?;
    enableClimateToggles?;
    defaultFilter;
    filterModalColumnHeaders;
    showHistory?;
};

type State = { filters; keyword };
class PagedListFilterBar extends React.Component<Props, State> {
    // TS CODE CHANGE. MOVED TO STATIC PROP TYPES
    static propTypes = {
        availableFilters: PropTypes.object.isRequired,
        defaultFilter: PropTypes.string.isRequired,
        updateState: PropTypes.func.isRequired,
        toGraphQLFilters: PropTypes.func.isRequired,
        filterModalColumnHeaders: PropTypes.func,
        enableSortButton: PropTypes.bool,
        sortButtonLabel: PropTypes.array
    };
    initialFilters;
    updateParentStateDebounced: any;
    constructor(props) {
        super(props);
        this.initialFilters = { keyword: null };

        for (let filterKey in this.props.availableFilters) {
            if (
                Object.prototype.hasOwnProperty.call(
                    this.props.availableFilters,
                    filterKey
                )
            ) {
                this.initialFilters[filterKey] =
                    typeof this.props.availableFilters[filterKey].default ===
                        "object" &&
                    Array.isArray(
                        this.props.availableFilters[filterKey].default
                    ) === false
                        ? {
                              ...this.props.availableFilters[filterKey].default
                          }
                        : this.props.availableFilters[filterKey].default;
            }
        }

        if (this.props.initialFilters) {
            this.initialFilters = {
                ...this.initialFilters,
                ...this.props.initialFilters
            };
        }

        if (this.props.filters) {
            this.initialFilters = {
                ...this.initialFilters,
                ...this.props.filters
            };
        }

        this.state = {
            keyword: this.initialFilters.keyword
                ? this.initialFilters.keyword
                : this.props.keyword
                ? this.props.keyword
                : "",
            filters: { ...this.initialFilters }
        };

        this.updateParentStateDebounced = debounce(
            this.updateParentState,
            500,
            {
                leading: false,
                trailing: true
            }
        );
    }

    onClickSort = () => {
        this.updateParentState(({ queryVariables }) => {
            let direction =
                queryVariables.sort.direction === "DESC" ? "ASC" : "DESC";
            return {
                queryVariables: {
                    ...queryVariables,
                    sort: {
                        ...queryVariables.sort,
                        direction
                    }
                }
            };
        });
    };

    onChangeKeyword = (keyword) => {
        this.setState({ keyword }, () => {
            this.updateParentStateDebounced({ keyword });
        });
    };

    onClickSearchClose = () => {
        this.onChangeKeyword("");
    };

    onSubmitFilters = (filters) => {
        this.setState({ filters }, () => this.props.updateState({ filters }));
        // we return true in order to signal that the modal can be closed
        return true;
    };

    onClickClearFilter = (event) => {
        const filterKey = event.target.getAttribute("data-filter");
        this.setState(
            ({ filters }) => {
                let filter = this.props.availableFilters[filterKey];
                filters[filterKey] = cloneDeep(filter.default);
                return {
                    filters
                };
            },
            () => {
                let filters = this.state.filters;
                this.props.updateState({ filters });
            }
        );
    };

    toGraphQLFilters = (filters) => {
        return this.props.toGraphQLFilters(filters);
    };

    updateParentState = (state) => {
        return this.props.updateState(state);
    };

    onTagOnlyChange = (event, name) => {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;

        const filters = this.state.filters;
        filters["sdgs"] =
            value === true
                ? [...(filters["sdgs"] ?? []), name]
                : filters["sdgs"]?.filter((t) => t !== name) ?? [];

        this.onSubmitFilters(filters);
    };

    onClimateChange = (event, name) => {
        const target = event.target;
        const value =
            target.type === "checkbox" ? target.checked : target.value;

        const filters = this.state.filters;

        if (value) {
            filters.climateApproaches = [
                ...new Set([...(filters.climateApproaches ?? []), name])
            ];
        } else {
            filters.climateApproaches =
                filters.climateApproaches?.filter((a) => a !== name) ?? [];
        }
        this.onSubmitFilters(filters);
    };

    render() {
        const {
            sortButtonLabel,
            enableSortButton,
            disableSearchButton,
            queryVariables,
            backgroundColor
        } = this.props;
        const { filters, keyword } = this.state;

        return (
            <React.Fragment>
                <style jsx global>
                    {`
                        .filters-bar {
                            background-color: ${backgroundColor
                                ? backgroundColor
                                : theme.colour.light};
                            clear: both;
                        }

                        .active-filters {
                            white-space: nowrap;
                            height: 100%;
                            margin-left: 10px;
                        }
                        .switch-wrapper {
                            padding: 4px;
                        }
                        .filter-button {
                            font-size: 0.8rem;
                        }
                        .input-group {
                            align-items: center;
                        }
                    `}
                </style>
                <div className="filters-bar mb-2">
                    {enableSortButton && (
                        <React.Fragment>
                            <SortButton
                                direction={queryVariables.sort.direction}
                                onClick={this.onClickSort}
                                label={sortButtonLabel}
                                className={"btn btn-dark float-right"}
                            />
                        </React.Fragment>
                    )}
                    <div className="row no-gutters">
                        <div className="col-auto">
                            <label className="sr-only" htmlFor="search">
                                Search
                            </label>
                            <div className="input-group">
                                {typeof disableSearchButton === "undefined" && (
                                    <React.Fragment>
                                        <SearchButton
                                            value={keyword}
                                            onChange={this.onChangeKeyword}
                                            onClickClose={
                                                this.onClickSearchClose
                                            }
                                            showSearch={
                                                typeof keyword !==
                                                    "undefined" &&
                                                keyword.length > 0
                                            }
                                            showHistory={
                                                this.props.showHistory === false
                                                    ? false
                                                    : true
                                            }
                                        />
                                        <span>&nbsp;</span>
                                    </React.Fragment>
                                )}
                                {this.props.availableFilters &&
                                    Object.keys(this.props.availableFilters)
                                        .length > 0 && (
                                        <React.Fragment>
                                            <FilterButton
                                                groupFilters={
                                                    this.props.groupFilters
                                                }
                                                defaultFilter={
                                                    this.props.defaultFilter
                                                }
                                                availableFilters={
                                                    this.props.availableFilters
                                                }
                                                filters={filters}
                                                onSubmit={this.onSubmitFilters}
                                                renderColumnHeaders={
                                                    this.props
                                                        .filterModalColumnHeaders
                                                }
                                            />
                                            <span>&nbsp;</span>
                                            <FilterButtonActiveFilters
                                                filters={filters}
                                                availableFilters={
                                                    this.props.availableFilters
                                                }
                                                onClickClearFilter={
                                                    this.onClickClearFilter
                                                }
                                            />
                                            {this.props.enableToggles && (
                                                <div className="d-flex flex-row justify-content-between align-items-center flex-wrap">
                                                    <ToggleSwitch
                                                        name={"GenderOnly"}
                                                        label={"Gender"}
                                                        checked={
                                                            this.props.filters[
                                                                "sdgs"
                                                            ]?.indexOf(
                                                                "05: Gender Equality"
                                                            ) > -1
                                                        }
                                                        onChange={(event) =>
                                                            this.onTagOnlyChange(
                                                                event,
                                                                "05: Gender Equality"
                                                            )
                                                        }
                                                    />
                                                    {this.props
                                                        .enableClimateToggles && (
                                                        <>
                                                            <ToggleSwitch
                                                                name={
                                                                    "ClimateOnly"
                                                                }
                                                                label={
                                                                    "Climate Adaptation"
                                                                }
                                                                checked={
                                                                    this.props.filters[
                                                                        "climateApproaches"
                                                                    ]?.indexOf(
                                                                        "Adaptation"
                                                                    ) > -1
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    this.onClimateChange(
                                                                        event,
                                                                        "Adaptation"
                                                                    )
                                                                }
                                                            />
                                                            <ToggleSwitch
                                                                name={
                                                                    "ClimateOnly"
                                                                }
                                                                label={
                                                                    "Climate Mitigation"
                                                                }
                                                                checked={
                                                                    this.props.filters[
                                                                        "climateApproaches"
                                                                    ]?.indexOf(
                                                                        "Mitigation"
                                                                    ) > -1
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    this.onClimateChange(
                                                                        event,
                                                                        "Mitigation"
                                                                    )
                                                                }
                                                            />
                                                            <ToggleSwitch
                                                                name={
                                                                    "ClimateOnly"
                                                                }
                                                                label={
                                                                    "Cross-Cutting (Mitigation+Adaptation)"
                                                                }
                                                                checked={
                                                                    this.props.filters[
                                                                        "climateApproaches"
                                                                    ]?.indexOf(
                                                                        "Hybrid"
                                                                    ) > -1
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    this.onClimateChange(
                                                                        event,
                                                                        "Hybrid"
                                                                    )
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                            </div>
                        </div>
                        <div className="col-7">
                            <div className="d-flex active-filters" />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PagedListFilterBar;
