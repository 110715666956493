/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import App, { AppProps } from "next/app";
import React from "react";
import ChatSupport from "../components/ChatSupport/ChatSupport";
import "../components/PageTransition";
import { initGA } from "../utils/Analytics";
import sentry from "../utils/Sentry";
import "../styles/index.scss";
import "../globals.css";
import "react-tooltip/dist/react-tooltip.css";

const { Sentry, captureException } = sentry();

type State = {
    hasError: boolean;
    errorEventId;
    loaded?: boolean;
};

export default class MyApp extends App<AppProps, {}, State> {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorEventId: undefined
        };
    }

    componentDidMount() {
        this.setState({ loaded: true });
        if (process.browser) {
            // @ts-ignore POSSIBLE ERROR FOUND not on window
            if (!window.GA_INITIALIZED) {
                initGA();
                // @ts-ignore POSSIBLE ERROR FOUND not on window
                window.GA_INITIALIZED = true;
            }
        }
        // Attach the copy event listener to the body
        document.body.addEventListener("copy", this.handleCopy);
    }

    componentWillUnmount(): void {
        // Clean up the event listener on unmount
        document.body.removeEventListener("copy", this.handleCopy);
    }

    scrambleText = (text) => {
        return text
            .split("")
            .sort(() => Math.random() - 0.5)
            .join("");
    };

    handleCopy = (e) => {
        const inPath =
            ["deal", "resource"].some((path) =>
                this.props.router.asPath.includes(path)
            ) || this.props.router.asPath === "/member";
        const selection = window.getSelection().toString(); // Get selected text
        if (selection && inPath) {
            const scrambled = this.scrambleText(selection); // Scramble the text
            e.clipboardData.setData("text/plain", scrambled); // Set the scrambled text
            e.preventDefault(); // Prevent the default copy action
        }
    };

    static async getInitialProps({ Component, ctx }) {
        try {
            let pageProps = {};

            if (Component.getInitialProps) {
                pageProps = await Component.getInitialProps(ctx);
            }

            return { pageProps };
        } catch (error) {
            // Capture errors that happen during a page's getInitialProps.
            // This will work on both client and server sides.
            const errorEventId = captureException(error, ctx);
            return {
                hasError: true,
                errorEventId,
                pageProps: {}
            };
        }
    }

    static getDerivedStateFromProps(props, state) {
        // If there was an error generated within getInitialProps, and we haven't
        // yet seen an error, we add it to this.state here
        return {
            hasError: props.hasError || state.hasError || false,
            errorEventId: props.errorEventId || state.errorEventId || undefined
        };
    }

    static getDerivedStateFromError() {
        // React Error Boundary here allows us to set state flagging the error (and
        // later render a fallback UI).
        return { hasError: true };
    }

    render() {
        const { Component, pageProps } = this.props;

        return this.state.hasError ? (
            <section>
                <h1>There was an error!</h1>
                <p>
                    <a
                        href="#"
                        onClick={() =>
                            Sentry.showReportDialog({
                                eventId: this.state.errorEventId
                            })
                        }
                    >
                        📣 Report this error
                    </a>
                </p>
            </section>
        ) : (
            <React.Fragment>
                <Component {...pageProps} />
                {/* @ts-ignore see notes in ChatSupport */}
                <ChatSupport />
            </React.Fragment>
        );
    }
}
