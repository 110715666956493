"use client";

import * as React from "react";
import Link from "next/link";

import { cn } from "../../../../../lib/utils";
import {
    BadgeInfo,
    Calendar,
    CalendarSearch,
    CandlestickChart,
    Clipboard,
    DatabaseZap,
    Flashlight,
    GraduationCap,
    HandCoins,
    Handshake,
    KeyIcon,
    Library,
    LucideIcon,
    Newspaper,
    Origami,
    Presentation,
    School,
    Sparkles,
    Sun,
    Target,
    TrendingUp,
    TrendingUpDown,
    UserSearch,
    UsersRound
} from "lucide-react";

import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger
} from "../../../../ui/navigation-menu";
import { SearchSheet } from "./SearchSheet";
import NavUserButton from "./NavUserButton";
import HelpButton from "./HelpButton";

enum MenuUserToggle {
    SHOW,
    HIDE
}
type MenuItem = {
    title: string;
    href: string;
    routeKey: string[];
    userToggle?: MenuUserToggle;
    description: string;
    icon?: LucideIcon;
    Badge?: JSX.ElementType;
};

const about: MenuItem[] = [
    {
        title: "Who We Are",
        href: "/about",
        routeKey: ["about"],
        description:
            "Read about Convergence’s vision, history, and important milestones.",
        icon: BadgeInfo
    },
    {
        title: "What We Do",
        href: "/about/what-we-do",
        routeKey: ["about-what-we-do"],
        description:
            "Convergence advances the field of blended finance through four key pathways.",
        icon: Target
    },
    {
        title: "People",
        href: "/about/people",
        routeKey: ["about-people"],
        description:
            "Learn more about our team, board, and grant funding committees.",
        icon: UsersRound
    },
    {
        title: "Funders & Partners",
        href: "/about/partners-funders",
        routeKey: ["about-partners-funders"],
        description:
            "Convergence is supported by an array of funders and partners.",
        icon: Handshake
    },
    {
        title: "Themes",
        href: "/about/themes",
        routeKey: ["about-themes"],
        description:
            "Learn more about specific impact themes our team is currently working on.",
        icon: Sparkles
    }
];
const network: MenuItem[] = [
    {
        title: "Membership",
        href: "/membership",
        routeKey: ["membership"],
        // userToggle: MenuUserToggle.HIDE,
        description:
            "Convergence members have exclusive access to curated content, data, capacity building opportunities, networking events, and more. ",
        icon: KeyIcon
    },
    {
        title: "Member Directory",
        href: "/member",
        routeKey: ["member"],
        // userToggle: MenuUserToggle.SHOW,
        description:
            "Connect with other members and read about their latest activity and past deals.",
        icon: UserSearch
    },
    {
        title: "Capacity Building",
        href: "/capacity-building",
        routeKey: ["capacity-building"],
        description:
            "Develop your blended finance capacity by exploring our online courses and learning programs, webinars, and in-person trainings.",
        icon: GraduationCap
    },
    {
        title: "Market Data",
        href: "/historical/deal/summary-analysis",
        routeKey: ["historical-deals", "investor-data"],
        description:
            "Access past deal and investor level data in our Historical Deals Database.",
        icon: DatabaseZap
    },
    {
        title: "Market Data Explorer",
        href: "/historical/deal/explorer",
        routeKey: ["historical-market-data-explorer"],
        description:
            "Exlore past deal and investor level data in our Historical Deals Database.",
        icon: TrendingUpDown,
        Badge: () => (
            <img
                src={"/static/icons/BetaIcon.png"}
                className="tw-h-5 sm:tw-h-5 tw-right-0 tw-top-1"
                alt="Explorer beta"
                title="Explorer beta"
            />
        )
    },
    {
        title: "Fundraising Deals",
        href: "/deals/summary-analysis",
        routeKey: ["deals"],
        description:
            "Explore live blended finance opportunities that are actively seeking capital.",
        icon: HandCoins
    }
];
const blendedFinance: MenuItem[] = [
    {
        title: "Blended Finance 101",
        href: "/blended-finance",
        routeKey: ["blended-finance"],
        description:
            "Check out our primer on blended finance to quickly get up to speed.",
        icon: Presentation
    },
    {
        title: "Online Learning",
        href: "/capacity-building#online-learning",
        routeKey: ["capacity-building"],
        description:
            "Take our online e-learning course “Demystifying Blended Finance” and receive a certificate of completion.",
        icon: School
    },
    {
        title: "Publications",
        href: "/resource",
        routeKey: ["knowledge", "knowledge-view"],
        description:
            "Explore our resource library of case studies, reports, and other knowledge products that have been authored by Convergence or developed by leading blended finance stakeholders.",
        icon: Library
    },
    {
        title: "State of Blended Finance",
        href: "/resource/state-of-blended-finance-2024-climate-edition/view",
        routeKey: ["sobf-2024-climate"],
        description:
            "Our semi-annual flagship publication on the state of the blended finance field.",
        icon: Clipboard
    }
];
const grants: MenuItem[] = [
    {
        title: "Design Funding Programs",
        href: "/design-funding",
        routeKey: ["design-funding"],
        description:
            "Explore opportunities for feasibility, proof of concept, or expansion stage grant support for your early-stage blended finance structure.",
        icon: Origami
    },
    {
        title: "Portfolio",
        href: "/design-funding/grant-portfolio",
        routeKey: ["design-funding-grant-portfolio"],
        description:
            "Learn about examples from and key metrics of our grant portfolio.",
        icon: CandlestickChart
    },
    {
        title: "Market Acceleration",
        href: "/design-funding/market-acceleration",
        routeKey: ["design-funding-market-acceleration"],
        description:
            "Convergence supports donors to implement their grant funding programs.",
        icon: TrendingUp
    },
    {
        title: "CC Facility",
        href: "/ccfacility",
        routeKey: ["ccfacility"],
        description:
            "A collaboration between Climate Policy Initiative and Convergence to advance market-ready climate finance structures.",
        icon: Sun
    }
];

const news: MenuItem[] = [
    {
        title: "News",
        href: "/news",
        routeKey: ["news-and-events"],
        description:
            "Check out Convergence's latest news, announcements, thought leadership and more.",
        icon: Newspaper
    },
    {
        title: "Member Spotlight",
        href: "/member-spotlight",
        routeKey: ["member-spotlight"],
        description:
            "Learn more about the work and focus of Convergence members and ecosystem.",
        icon: Flashlight
    }
];
const events: MenuItem[] = [
    {
        title: "Calendar",
        href: "/calendar",
        routeKey: ["event-calendar"],
        description: "Check out upcoming blended finance events and webinars.",
        icon: Calendar
    },
    {
        title: "Past Events",
        href: "/calendar/library",
        routeKey: ["event-calendar-library"],
        description:
            "A library of our past events with event recordings and more.",
        icon: CalendarSearch
    }
];

export default function NavMenu({
    currentRouteKey,
    user,
    authorizationService
}) {
    const [hoveredItem, setHoveredItem] = React.useState("");

    const MenuItems = ({
        menuItems,
        defaultContent
    }: {
        menuItems: MenuItem[];
        defaultContent: string;
    }) => (
        <div className="tw-grid tw-w-[400px] tw-gap-3 tw-p-4 md:tw-w-[500px] md:tw-grid-cols-2 lg:tw-w-[695px]">
            <ul className="">
                {menuItems
                    .filter((item) => {
                        if (user && item.userToggle === MenuUserToggle.HIDE) {
                            return false;
                        }
                        if (user && item.userToggle === MenuUserToggle.SHOW) {
                            return item;
                        }
                        if (!user && item.userToggle === MenuUserToggle.HIDE) {
                            return item;
                        }
                        if (!user && item.userToggle === MenuUserToggle.SHOW) {
                            return false;
                        }
                        return item;
                    })
                    .map((item) => (
                        <ListItem
                            key={item.title}
                            title={item.title}
                            icon={item.icon}
                            Badge={item.Badge}
                            href={item.href}
                            onMouseEnter={() =>
                                setHoveredItem(item.description)
                            }
                            onMouseLeave={() => setHoveredItem("")}
                            routeKey={item.routeKey}
                            currentRouteKey={currentRouteKey}
                        >
                            {item.description}
                        </ListItem>
                    ))}
            </ul>
            <p className="tw-text-sm tw-rounded-md tw-leading-snug tw-bg-primary tw-text-secondary-foreground tw-text-shadow tw-p-4 data-[motion^=from-]:tw-animate-in data-[motion^=to-]:tw-animate-out data-[motion^=from-]:tw-fade-in data-[motion^=to-]:tw-fade-out data-[motion=from-end]:tw-slide-in-from-bottom-52 data-[motion=from-start]:tw-slide-in-from-top-52 data-[motion=to-end]:tw-slide-out-to-bottom-52 data-[motion=to-start]:tw-slide-out-to-top-52">
                {hoveredItem || defaultContent}
            </p>
        </div>
    );

    return (
        <div className="tw-hidden lg:tw-flex tw-flex-row tw-justify-between tw-items-end tw-w-full tw-pl-4">
            <NavigationMenu className="tw-justify-start" user={user}>
                <NavigationMenuList>
                    {/* {user ? (
                        <NavigationMenuItem>
                            <Link href="/dashboard" legacyBehavior passHref>
                                <NavigationMenuLink
                                    className={navigationMenuTriggerStyle()}
                                >
                                    Dashboard
                                </NavigationMenuLink>
                            </Link>
                        </NavigationMenuItem>
                    ) : null} */}

                    <NavigationMenuItem>
                        <NavigationMenuTrigger className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50">
                            <NavigationMenuLink
                                href="/about"
                                className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-0 tw-py-0 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none  hover:tw-text-primary-foreground disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50"
                                active={isActive(["about"], currentRouteKey)}
                            >
                                About
                            </NavigationMenuLink>
                        </NavigationMenuTrigger>
                        <NavigationMenuContent>
                            <MenuItems
                                menuItems={about}
                                defaultContent="Convergence is a not-for-profit organization headquartered in Canada. Learn more about us, who we are, what we do, and who our people, partners, and funders are."
                            />
                        </NavigationMenuContent>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <NavigationMenuTrigger className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50">
                            <NavigationMenuLink
                                href="/membership"
                                className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-0 tw-py-0 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none  hover:tw-text-primary-foreground disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50"
                                active={currentRouteKey === "membership"}
                            >
                                Members
                            </NavigationMenuLink>
                        </NavigationMenuTrigger>
                        <NavigationMenuContent>
                            <MenuItems
                                menuItems={network}
                                defaultContent="Convergence members have exclusive access to curated content, data, capacity building opportunities, networking events, and more."
                            />
                        </NavigationMenuContent>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <NavigationMenuTrigger className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50">
                            <NavigationMenuLink
                                href="/resource"
                                className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-0 tw-py-0 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none  hover:tw-text-primary-foreground disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50"
                                active={isActive(
                                    ["knowledge", "knowledge-view"],
                                    currentRouteKey
                                )}
                            >
                                Resources
                            </NavigationMenuLink>
                        </NavigationMenuTrigger>
                        <NavigationMenuContent>
                            <MenuItems
                                menuItems={blendedFinance}
                                defaultContent="Convergence authors and aggregates key resources covering topics across the blended finance ecosystem."
                            />
                        </NavigationMenuContent>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        {/* <NavigationMenuTrigger>Grants</NavigationMenuTrigger> */}
                        <NavigationMenuTrigger className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50">
                            <NavigationMenuLink
                                href="/design-funding"
                                className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-0 tw-py-0 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none  hover:tw-text-primary-foreground disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50"
                                active={isActive(
                                    ["design-funding"],
                                    currentRouteKey
                                )}
                            >
                                Grants
                            </NavigationMenuLink>
                        </NavigationMenuTrigger>

                        <NavigationMenuContent>
                            <MenuItems
                                menuItems={grants}
                                defaultContent="Convergence offers grant funding to practitioners through our various design funding and market acceleration programs."
                            />
                        </NavigationMenuContent>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <NavigationMenuTrigger className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50">
                            <NavigationMenuLink
                                href="/news"
                                className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-0 tw-py-0 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none  hover:tw-text-primary-foreground disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50"
                                active={isActive(
                                    ["news-and-events"],
                                    currentRouteKey
                                )}
                            >
                                News
                            </NavigationMenuLink>
                        </NavigationMenuTrigger>

                        <NavigationMenuContent>
                            <MenuItems
                                menuItems={news}
                                defaultContent="Check out Convergence's latest news, announcements, thought leadership and more."
                            />
                        </NavigationMenuContent>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        {/* <NavigationMenuTrigger>Events</NavigationMenuTrigger> */}
                        <NavigationMenuTrigger className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50">
                            <NavigationMenuLink
                                href="/calendar"
                                className="tw-group tw-inline-flex tw-h-full tw-w-max tw-items-center tw-justify-center tw-bg-transparent tw-text-white tw-px-0 tw-py-0 tw-text-sm tw-font-medium tw-transition-colors  focus:tw-bg-primary focus:tw-text-primary-foreground focus:tw-outline-none  hover:tw-text-primary-foreground disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[active]:tw-underline tw-underline-offset-[12px] tw-decoration-2 data-[state=open]:tw-bg-primary/50"
                                active={isActive(
                                    ["event-calendar"],
                                    currentRouteKey
                                )}
                            >
                                Events
                            </NavigationMenuLink>
                        </NavigationMenuTrigger>

                        <NavigationMenuContent>
                            <MenuItems
                                menuItems={events}
                                defaultContent="Convergence regularly organizes member events, grant window information sessions, and networking events to bring together the blended finance community."
                            />
                        </NavigationMenuContent>
                    </NavigationMenuItem>
                </NavigationMenuList>
            </NavigationMenu>
            <div className="tw-flex tw-flex-row tw-px-2 xl:tw-px-4 tw-py-2 tw-justify-between tw-text-sm tw-h-full tw-self-start">
                <div className="xl:tw-pr-4 tw-self-end tw-h-full">
                    <SearchSheet
                        user={user}
                        authorizationService={authorizationService}
                        iconOnly={false}
                    />
                </div>

                {!user ? (
                    <div className="tw-flex tw-flex-row tw-text-white tw-px-1">
                        {/* <Link href="/login">
                            <a className="tw-text-white tw-px-1">membership</a>
                        </Link>{" "}
                        | */}
                        <Link href="/login">
                            <a className="tw-text-white tw-px-1">login</a>
                        </Link>{" "}
                        |
                        <Link href="/sign-up">
                            <a className="tw-text-white tw-px-1">register</a>
                        </Link>
                    </div>
                ) : (
                    <div className="tw-flex tw-flex-row tw-text-white tw-px-1">
                        <HelpButton
                            user={user}
                            currentRouteKey={currentRouteKey}
                            darkTheme={false}
                            authorizationService={authorizationService}
                        />

                        <NavUserButton
                            user={user}
                            currentRouteKey={currentRouteKey}
                            darkTheme={false}
                            authorizationService={authorizationService}
                        />
                    </div>
                )}

                {/* <div className="tw-pl-2 tw-self-end">
                    <SearchSheet
                        user={user}
                        authorizationService={authorizationService}
                    />
                </div> */}
            </div>
        </div>
    );
}

const ListItem = React.forwardRef<
    React.ElementRef<"a">,
    React.ComponentPropsWithoutRef<"a" | any>
>(
    (
        {
            className,
            title,
            icon,
            Badge,
            children,
            routeKey,
            currentRouteKey,
            ...props
        },
        ref
    ) => {
        const Component: LucideIcon = icon;
        const active = isActive(routeKey, currentRouteKey);

        return (
            <li className="tw-flex tw-flex-row tw-items-center">
                <div className="tw-text-md tw-font-medium tw-leading-none tw-pb-1">
                    {icon ? <Component size={18} /> : null}
                </div>

                <NavigationMenuLink asChild active={active}>
                    <a
                        ref={ref}
                        className={cn(
                            "tw-w-full tw-text-shadow tw-text-white tw-select-none tw-space-y-1 tw-p-3 tw-leading-none tw-no-underline tw-outline-none tw-transition-colors tw-rounded-md hover:tw-grow hover:tw-text-primary-foreground focus:tw-bg-primary focus:tw-text-primary-foreground data-[active]:tw-underline tw-underline-offset-4 tw-decoration-2",
                            className
                        )}
                        {...props}
                    >
                        <div className="tw-relative tw-w-full">
                            <p className="tw-relative tw-group">
                                <div className="tw-text-sm tw-font-medium tw-leading-none">
                                    {title}
                                    {Badge && (
                                        <span className="badge tw-absolute tw--top-2">
                                            <Badge />
                                        </span>
                                    )}
                                </div>
                                <span className="tw-absolute tw--bottom-1 tw-left-1/2 tw-w-0 tw-h-[2px] tw-bg-white group-hover:tw-w-1/2 group-hover:tw-transition-all"></span>
                                <span className="tw-absolute tw--bottom-1 tw-right-1/2 tw-w-0 tw-h-[2px] tw-bg-white group-hover:tw-w-1/2 group-hover:tw-transition-all"></span>
                            </p>
                            <p className="tw-line-clamp-2 tw-text-sm tw-leading-snug tw-text-secondary-foreground tw-text-shadow">
                                {/* {children} */}
                            </p>
                        </div>
                    </a>
                </NavigationMenuLink>
            </li>
        );
    }
);
ListItem.displayName = "ListItem";

function isActive(routeKeys, currentRouteKey) {
    return routeKeys
        .map((key) => {
            // console.log({ key }, currentRouteKey.indexOf(key));
            return currentRouteKey?.indexOf(key) === 0;
        })
        .includes(true);
}
